import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import * as loadingStatus from '../../../../../../../constants/loading-status';

export default function ActivationAlert({ status, password, confirmation }) {
    if (!status || status === loadingStatus.STARTED) {
        return null;
    }
    return (
        <Alert color="warning">
            {password !== confirmation ? (
                <div>The passwords that you entered do not match</div>
            ) : (
                <div>
                    The password that you entered does not meet one of the
                    following requirements:
                    <ol>
                        <li>Must be 9 or more characters</li>
                        <li>Must not be a commonly-used password</li>
                        <li>Must not be similar to your username</li>
                    </ol>
                </div>
            )}
        </Alert>
    );
}

ActivationAlert.propTypes = {
    status: PropTypes.string,
    password: PropTypes.string.isRequired,
    confirmation: PropTypes.string.isRequired,
};
