import * as types from '../constants/action-types';
import { createLoadingAction } from './action-helpers';
import {
    getDailyHistoryStats,
    getErrorFileInfo,
    getExpiringConsenters,
    getFilteredConsenters,
    getFilteredGlobalOptOuts,
    getFilteredCandidates,
} from '../utils/backend';

/**
 * Generic function to retrieve the total number of consenters with the given
 * filter criteria
 * @param status Consenter status, e.g. 'granted', 'revoked', etc...
 * @param type Action type
 * @param loadingStatusType Loading status action type
 */
export const loadFilteredConsenters = (status, type, loadingStatusType) =>
    createLoadingAction(loadingStatusType, filterOptions => async dispatch => {
        dispatch({
            type,
            [status]: null,
        });
        const filteredTotal = await getFilteredConsenters({
            ...filterOptions,
            status,
        });
        dispatch({ type, [status]: filteredTotal });
    });

/**
 * Saves the values from the filters that were selected at the last time
 * the submit button was pressed
 */
export const cacheFilterValues = createLoadingAction(
    types.CACHE_FILTER_VALUES_STATUS,
    (start_date, end_date, filterOptions) => dispatch => {
        dispatch({
            type: types.CACHE_FILTER_VALUES,
            startDate: start_date,
            endDate: end_date,
            filterOptions: filterOptions,
        });
    }
);

/**
 * Loads the total number of consenters who have a status of 'granted' and
 * saves it to redux
 */
export const loadFilteredConsentersGranted = loadFilteredConsenters(
    'granted',
    types.LOAD_FILTERED_CONSENTERS_GRANTED,
    types.LOAD_FILTERED_CONSENTERS_GRANTED_STATUS
);

/**
 * Loads the total number of consenters who have a status of 'revoked' and
 * saves it to redux
 */
export const loadFilteredConsentersRevoked = loadFilteredConsenters(
    'revoked',
    types.LOAD_FILTERED_CONSENTERS_REVOKED,
    types.LOAD_FILTERED_CONSENTERS_REVOKED_STATUS
);

/**
 * Loads the total number of consenters who have a status of 'unknown' and
 * saves it to redux
 */
export const loadFilteredConsentersUnknown = loadFilteredConsenters(
    'unknown',
    types.LOAD_FILTERED_CONSENTERS_UNKNOWN,
    types.LOAD_FILTERED_CONSENTERS_UNKNOWN_STATUS
);

/**
 * Loads the total number of consenters who are globally opted out and
 * saves it to redux
 */
export const loadFilteredGlobalOptOuts = createLoadingAction(
    types.LOAD_FILTERED_GLOBAL_OPT_OUTS_STATUS,
    filterOptions => async dispatch => {
        const filteredTotal = await getFilteredGlobalOptOuts(filterOptions);
        dispatch({
            type: types.LOAD_FILTERED_GLOBAL_OPT_OUTS,
            global_opt_outs: filteredTotal,
        });
    }
);

/**
 * Loads the filtered list of consenters and
 * saves it to redux
 */
export const loadFilteredCandidates = createLoadingAction(
    types.LOAD_FILTERED_CONSENTERS_STATUS,
    (candidateIds, filterOptions) => async dispatch => {
        const filteredConsenters = await getFilteredCandidates(
            candidateIds,
            filterOptions
        );
        dispatch({
            type: types.LOAD_FILTERED_CONSENTERS,
            filtered_consenters: filteredConsenters,
        });
    }
);

/**
 * Loads the total number of consenters who's preferences will expire in 365
 * days and saves it to redux
 */
export const loadFilteredConsentersExpiringIn365Days = createLoadingAction(
    types.LOAD_FILTERED_CONSENTERS_EXPIRING_365_DAYS_STATUS,
    filterOptions => async dispatch => {
        dispatch({
            type: types.LOAD_FILTERED_CONSENTERS_EXPIRING_365_DAYS,
            expire_in_365: { total: null, link: null },
        });
        const expireIn365 = await getExpiringConsenters(365, filterOptions);
        dispatch({
            type: types.LOAD_FILTERED_CONSENTERS_EXPIRING_365_DAYS,
            expire_in_365: expireIn365,
        });
    }
);

/**
 * Loads the total number of consenters who's preferences will expire in 180
 * days and saves it to redux
 */
export const loadFilteredConsentersExpiringIn180Days = createLoadingAction(
    types.LOAD_FILTERED_CONSENTERS_EXPIRING_180_DAYS_STATUS,
    filterOptions => async dispatch => {
        dispatch({
            type: types.LOAD_FILTERED_CONSENTERS_EXPIRING_180_DAYS,
            expire_in_180: { total: null, link: null },
        });
        const expireIn180 = await getExpiringConsenters(180, filterOptions);
        dispatch({
            type: types.LOAD_FILTERED_CONSENTERS_EXPIRING_180_DAYS,
            expire_in_180: expireIn180,
        });
    }
);

/**
 * Loads the total number of consenters who's preferences will expire in 60
 * days and saves it to redux
 */
export const loadFilteredConsentersExpiringIn60Days = createLoadingAction(
    types.LOAD_FILTERED_CONSENTERS_EXPIRING_60_DAYS_STATUS,
    filterOptions => async dispatch => {
        dispatch({
            type: types.LOAD_FILTERED_CONSENTERS_EXPIRING_60_DAYS,
            expire_in_60: { total: null, link: null },
        });
        const expireIn60 = await getExpiringConsenters(60, filterOptions);
        dispatch({
            type: types.LOAD_FILTERED_CONSENTERS_EXPIRING_60_DAYS,
            expire_in_60: expireIn60,
        });
    }
);

/**
 * Loads the total number of consenters who's preferences have expired and
 * saves it to redux
 */
export const loadFilteredExpiredConsenters = createLoadingAction(
    types.LOAD_FILTERED_CONSENTERS_EXPIRED_STATUS,
    filterOptions => async dispatch => {
        dispatch({
            type: types.LOAD_FILTERED_CONSENTERS_EXPIRED,
            expired: { total: null, link: null },
        });
        const expired = await getExpiringConsenters(0, filterOptions);
        dispatch({
            type: types.LOAD_FILTERED_CONSENTERS_EXPIRED,
            expired: expired,
        });
    }
);

/**
 * Loads the daily history stats of how many consenters have had their
 * preferences updated or added
 */
export const loadDailyHistoryStats = createLoadingAction(
    types.LOAD_DAILY_HISTORY_STATS_STATUS,
    (start_date, end_date, filterOptions) => async dispatch => {
        const dailyHistoryStats = await getDailyHistoryStats(
            start_date,
            end_date,
            filterOptions
        );
        dispatch({
            type: types.LOAD_DAILY_HISTORY_STATS,
            dailyHistoryStats,
        });
    }
);

/**
 * Loads the information about error files in S3
 * for all days within the specified date range.
 */
export const loadErrorFileInfo = createLoadingAction(
    types.LOAD_ERROR_FILE_INFO_STATUS,
    (start_date, end_date) => async dispatch => {
        const errorFileInfo = await getErrorFileInfo(start_date, end_date);
        dispatch({
            type: types.LOAD_ERROR_FILE_INFO,
            errorFileInfo: errorFileInfo,
        });
    }
);

/**
 * Loads all consenter stats
 */
export const loadAllStats = ({
    start_date,
    end_date,
    ...filterOptions
}) => async dispatch => {
    cacheFilterValues(start_date, end_date, filterOptions)(dispatch);
    loadFilteredConsentersGranted(filterOptions)(dispatch);
    loadFilteredConsentersRevoked(filterOptions)(dispatch);
    loadFilteredConsentersUnknown(filterOptions)(dispatch);
    loadFilteredGlobalOptOuts(filterOptions)(dispatch);
    loadFilteredConsentersExpiringIn365Days(filterOptions)(dispatch);
    loadFilteredConsentersExpiringIn180Days(filterOptions)(dispatch);
    loadFilteredConsentersExpiringIn60Days(filterOptions)(dispatch);
    loadFilteredExpiredConsenters(filterOptions)(dispatch);
    loadDailyHistoryStats(start_date, end_date, filterOptions)(dispatch);
    loadErrorFileInfo(start_date, end_date)(dispatch);
};

/**
 * Filters a list of candidates ids based on filter options.
 */
export const filterCandidates = (
    candidateIds,
    filterOptions
) => async dispatch => {
    loadFilteredCandidates(candidateIds, filterOptions)(dispatch);
};

/**
 * Switch the selected tile at the top of the dashboard
 */
export const selectDashboardTile = tileName => async dispatch => {
    dispatch({
        type: types.SELECT_DASHBOARD_TILE,
        tileName: tileName,
    });
};
