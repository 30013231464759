import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';

import LoadingSpinner from '../../../../../shared/LoadingSpinner';
import { googleAnalyticsEvent } from '../../../../../../../actions/analytics';
import * as ExportStatus from '../../../../../../../constants/export-status';
import StatusCell from '../../../../../shared/StatusCell';
import { STARTED } from '../../../../../../../constants/loading-status';

function generateStatsObject(status, consenters) {
    var statsRow = {
        totalCell: 0,
        emailExport: true,
        exportValue: '',
        category: '',
        action: '',
    };

    switch (status) {
        case 'granted':
            statsRow.totalCell = consentersTotalValue(consenters.granted);
            statsRow.exportValue = ExportStatus.GRANTED;
            statsRow.category = ExportStatus.GRANTED;
            statsRow.action = 'Performed Export for Granted';
            break;
        case 'revoked':
            statsRow.totalCell = consentersTotalValue(consenters.revoked);
            statsRow.exportValue = ExportStatus.REVOKED;
            statsRow.category = ExportStatus.REVOKED;
            statsRow.action = 'Performed Export for Revoked';
            break;
        case 'unknown':
            statsRow.totalCell = consentersTotalValue(consenters.unknown);
            statsRow.exportValue = ExportStatus.UNKNOWN;
            statsRow.category = ExportStatus.UNKNOWN;
            statsRow.action = 'Performed Export for Unknown';
            break;
        case 'global opt out':
            statsRow.totalCell = consentersTotalValue(
                consenters.global_opt_outs
            );
            statsRow.exportValue = ExportStatus.GLOBAL_OPT_OUT;
            statsRow.category = ExportStatus.GLOBAL_OPT_OUT;
            statsRow.action = 'Performed Export for Global Opt Out';
            break;
        case 'Expire in 365 days':
            statsRow.totalCell = consentersTotalValue(
                consenters.expire_in_365,
                true
            );
            statsRow.exportValue = consenters.expire_in_365.value.link;
            statsRow.category = 'Expire in 365 days';
            statsRow.action =
                'Performed Export for Consent Expirations (365 days)';
            statsRow.emailExport = false;
            break;
        case 'Expire in 180 days':
            statsRow.totalCell = consentersTotalValue(
                consenters.expire_in_180,
                true
            );
            statsRow.exportValue = consenters.expire_in_180.value.link;
            statsRow.category = 'Expire in 180 days';
            statsRow.action =
                'Performed Export for Consent Expirations (180 days)';
            statsRow.emailExport = false;
            break;
        case 'Expire in 60 days':
            statsRow.totalCell = consentersTotalValue(
                consenters.expire_in_60,
                true
            );
            statsRow.exportValue = consenters.expire_in_60.value.link;
            statsRow.category = 'Expire in 60 days';
            statsRow.action =
                'Performed Export for Consent Expirations (60 days)';
            statsRow.emailExport = false;
            break;
        case 'Expired to date':
            statsRow.totalCell = consentersTotalValue(consenters.expired, true);
            statsRow.exportValue = consenters.expired.value.link;
            statsRow.category = 'Expired to date';
            statsRow.action =
                'Performed Export for Consent Expirations (to date)';
            statsRow.emailExport = false;
            break;
        default:
            break;
    }

    return statsRow;
}

function consentersTotalValue(stat, subObjectTotal = false) {
    let value = stat.value;
    if (stat.value && subObjectTotal) {
        value = stat.value.total;
    }

    return (
        <LoadingSpinner
            isLoading={stat.status === STARTED}
            component={() => <span>{value}</span>}
        />
    );
}

function ConsentersTotalCell(props) {
    switch (props.status) {
        case 'granted':
            return consentersTotalValue(props.consenters.granted);
        case 'revoked':
            return consentersTotalValue(props.consenters.revoked);
        case 'unknown':
            return consentersTotalValue(props.consenters.unknown);
        case 'global opt out':
            return consentersTotalValue(props.consenters.global_opt_outs);
        case 'Expire in 365 days':
            return consentersTotalValue(props.consenters.expire_in_365, true);
        case 'Expire in 180 days':
            return consentersTotalValue(props.consenters.expire_in_180, true);
        case 'Expire in 60 days':
            return consentersTotalValue(props.consenters.expire_in_60, true);
        case 'Expired to date':
            return consentersTotalValue(props.consenters.expired, true);
        default:
            return <span>n/a</span>;
    }
}

ConsentersTotalCell.propTypes = {
    status: PropTypes.string.isRequired,
    consenters: PropTypes.object.isRequired,
};

function toTitleCase(str) {
    if (!str) {
        return '';
    }

    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

/**
 * Formats filter selections into the an object with all possible
 * status options to populate the total consenters main chart
 */
function formatFilterSelections(filterSelections) {
    var formattedSelections = {
        brand: toTitleCase(filterSelections.brand),
        program: toTitleCase(filterSelections.program_name),
        consentType: toTitleCase(filterSelections.consent_type),
        channel: toTitleCase(filterSelections.channel),
        system: toTitleCase(filterSelections.source_system_name),
        statuses: [
            'granted',
            'revoked',
            'unknown',
            'global opt out',
            'Expire in 365 days',
            'Expire in 180 days',
            'Expire in 60 days',
            'Expired to date',
        ],
    };
    return formattedSelections;
}

function filteredValueCell(filteredValue, key, rowSpan) {
    return (
        <td key={key} className="table-light" rowSpan={rowSpan}>
            {filteredValue || 'All'}
        </td>
    );
}

/**
 * Maps an array of preferences into rows for rendering
 */
function mapPreferencesToRows(selections, consenters, onExport) {
    const preferences = formatFilterSelections(selections);
    const rows = [];
    let cells = [];
    const rowSpan = preferences.statuses.length;

    cells.push(filteredValueCell(preferences.brand, cells.length, rowSpan));
    cells.push(filteredValueCell(preferences.program, cells.length, rowSpan));
    cells.push(
        filteredValueCell(preferences.consentType, cells.length, rowSpan)
    );
    cells.push(filteredValueCell(preferences.channel, cells.length, rowSpan));
    cells.push(filteredValueCell(preferences.system, cells.length, rowSpan));
    for (var status in preferences.statuses) {
        const statusDictionary = generateStatsObject(
            preferences.statuses[status],
            consenters
        );

        cells.push(
            <td key={cells.length}>
                <StatusCell status={preferences.statuses[status]} />
            </td>
        );

        cells.push(<td key={cells.length}>{statusDictionary.totalCell}</td>);

        if (statusDictionary.emailExport) {
            cells.push(
                <td key={cells.length}>
                    <a
                        href=""
                        onClick={e => {
                            e.preventDefault();
                            onExport(statusDictionary.exportValue);
                            googleAnalyticsEvent({
                                category: statusDictionary.category,
                                action: statusDictionary.action,
                                label: JSON.stringify(selections),
                            });
                        }}
                    >
                        Export list
                    </a>
                </td>
            );
        } else {
            cells.push(
                <td key={cells.length}>
                    <a
                        href=""
                        onClick={() => {
                            window.open(statusDictionary.exportValue);
                            googleAnalyticsEvent({
                                category: statusDictionary.category,
                                action: statusDictionary.action,
                                label: JSON.stringify(selections),
                            });
                        }}
                    >
                        Export list
                    </a>
                </td>
            );
        }
        rows.push(<tr key={rows.length}>{cells}</tr>);
        cells = [];
    }
    return rows;
}

export default function FilteredStatsTable(props) {
    return (
        <Table
            responsive
            className="table table-bordered table-striped consent-preference-table"
        >
            <tbody>
                <tr>
                    <th>Brand</th>
                    <th>Program</th>
                    <th>Consent type</th>
                    <th>Channel</th>
                    <th>System</th>
                    <th>Status</th>
                    <th>Consenters</th>
                    <th />
                </tr>
                {mapPreferencesToRows(
                    props.filterSelections,
                    props.consenters,
                    props.onExport
                )}
            </tbody>
        </Table>
    );
}
FilteredStatsTable.propTypes = {
    filterSelections: PropTypes.object.isRequired,
    consenters: PropTypes.object.isRequired,
    onExport: PropTypes.func.isRequired,
};
