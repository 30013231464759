import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';

function HistoryDateSelection({ onChange, selections }) {
    /*
     * Inline form for selecting Dates and submitting a Filter Request
     * Stylesheet: styles/date_selection.scss
     */

    const handleDateChange = key => newValue => {
        onChange(key, newValue);
    };

    const today = new Date();

    // Historical data header, two Datepickers, and a Submit button
    return (
        <div className="date-selection-wrapper">
            <Label>From</Label>
            <DatePicker
                selected={selections.start_date}
                onChange={handleDateChange('start_date')}
                maxDate={today}
                dateFormat="MMMM d, yyyy"
            />
            <Label>To</Label>
            <DatePicker
                selected={selections.end_date}
                onChange={handleDateChange('end_date')}
                maxDate={today}
                dateFormat="MMMM d, yyyy"
            />
        </div>
    );
}

HistoryDateSelection.propTypes = {
    onChange: PropTypes.func.isRequired,
    selections: PropTypes.object.isRequired,
};

export default connect()(HistoryDateSelection);
