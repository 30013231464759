import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
    equalsIgnoreCase,
    formatConsentTypeText,
    prettifyConsenterTypeText,
} from '../../../../../utils/view-helpers';
import HistoryDateSelection from './components/HistoryDateSelection';

import './sidebar.scss';

const handleFilterOptionChange = callback => fieldName => event => {
    let fieldVal = event.target.value;
    callback(fieldName, fieldVal);
};

const transformOptions = (options, labelPrettifier = formatConsentTypeText) =>
    options.map(option => ({ label: labelPrettifier(option), value: option }));

function SidebarDropdown(props) {
    return (
        props.show && (
            <FormGroup>
                <Label>{props.label}</Label>
                <Input
                    type="select"
                    value={props.value}
                    onChange={props.onChange}
                >
                    <option value="">Any</option>
                    {props.options.map(({ label, value }) => (
                        <option key={value} value={value}>
                            {label}
                        </option>
                    ))}
                </Input>
            </FormGroup>
        )
    );
}
SidebarDropdown.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    show: PropTypes.bool,
};
SidebarDropdown.defaultProps = { show: true };

export default class Sidebar extends React.Component {
    renderBatchUpload() {
        if (!this.props.isSuperuser) {
            return null;
        }
        return (
            <div id="batch-upload-content">
                <Link id="batch-upload-link" to="/batch_ingest">
                    <Button size="sm" block>
                        Batch Upload
                    </Button>
                </Link>
            </div>
        );
    }

    renderFilterCandidates() {
        const handleCandidatesChange = event => {
            const candidateIds = event.target.value;
            this.props.onCandidatesChange(candidateIds);
        };
        return (
            <div>
                <Label id="filter-candidates-header">
                    Filter Candidate IDs
                </Label>
                <Input
                    type="textarea"
                    value={this.props.candidateIds}
                    onChange={handleCandidatesChange}
                />
                <Button
                    id="filter-ids-button"
                    size="sm"
                    onClick={this.props.onFilterIds}
                    block
                >
                    Filter IDs
                </Button>
            </div>
        );
    }

    render() {
        const batchUploadContent = this.renderBatchUpload();
        const renderCandidatesContent = this.renderFilterCandidates();

        const handleChange = handleFilterOptionChange(this.props.onChange);
        return (
            <div className="sidebar">
                <h1 className="sidebar-header">Show values for:</h1>
                <Form
                    className="sidebar-filters"
                    onSubmit={event => {
                        event.preventDefault();
                        if (!this.props.isSubmitting) {
                            this.props.onClick(this.props.selections);
                        }
                        return false;
                    }}
                >
                    <SidebarDropdown
                        label="System"
                        value={this.props.selections.source_system_name}
                        onChange={handleChange('source_system_name')}
                        options={transformOptions(
                            this.props.source_system_names
                        )}
                    />
                    <SidebarDropdown
                        label="Brand"
                        value={this.props.selections.brand}
                        onChange={handleChange('brand')}
                        options={transformOptions(this.props.brands)}
                    />
                    <SidebarDropdown
                        label="Program"
                        value={this.props.selections.program_name}
                        onChange={handleChange('program_name')}
                        options={transformOptions(this.props.program_names)}
                    />
                    <SidebarDropdown
                        label="Consent type"
                        value={this.props.selections.consent_type}
                        onChange={handleChange('consent_type')}
                        options={transformOptions(this.props.consent_types)}
                    />
                    <SidebarDropdown
                        label="Service"
                        value={this.props.selections.service_name}
                        onChange={handleChange('service_name')}
                        options={transformOptions(this.props.service_names)}
                        show={equalsIgnoreCase(
                            this.props.selections.consent_type,
                            'Service'
                        )}
                    />
                    <SidebarDropdown
                        label="Consent type - Channel"
                        value={this.props.selections.channel}
                        onChange={handleChange('channel')}
                        options={transformOptions(this.props.channels)}
                    />
                    <SidebarDropdown
                        label="Consenter type"
                        value={this.props.selections.consenter_type}
                        onChange={handleChange('consenter_type')}
                        options={transformOptions(
                            this.props.consenter_types,
                            prettifyConsenterTypeText
                        )}
                    />
                    <SidebarDropdown
                        label="Consentee name"
                        value={this.props.selections.consentee_name}
                        onChange={handleChange('consentee_name')}
                        options={transformOptions(this.props.consentee_names)}
                    />
                    <HistoryDateSelection />
                    <Button disabled={this.props.isSubmitting} size="sm" block>
                        Submit
                    </Button>
                </Form>
                <hr className="sidebar-separator" />
                {renderCandidatesContent}
                <hr className="sidebar-separator" />
                {batchUploadContent}
            </div>
        );
    }
}

Sidebar.propTypes = {
    onChange: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    selections: PropTypes.object.isRequired,
    source_system_names: PropTypes.array.isRequired,
    brands: PropTypes.array.isRequired,
    program_names: PropTypes.array.isRequired,
    consent_types: PropTypes.array.isRequired,
    service_names: PropTypes.array.isRequired,
    channels: PropTypes.array.isRequired,
    consenter_types: PropTypes.array.isRequired,
    consentee_names: PropTypes.array.isRequired,
    isSuperuser: PropTypes.bool.isRequired,
    onFilterIds: PropTypes.func.isRequired,
    candidateIds: PropTypes.string.isRequired,
    onCandidatesChange: PropTypes.func.isRequired,
};
