import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import * as actions from '../../../../../actions/auth-actions';
import * as loadingStatus from '../../../../../constants/loading-status';
import { loadingUnsuccessful } from '../../../shared/selectors';

import ActivationAlert from './components/ActivationAlert';
import ActivationForm from './ActivationForm';

/**
 * ActivationFormContainer component
 */
function ActivationFormContainer({
    onCredentialChange,
    onActivationSubmit,
    confirmation,
    password,
    activationStatus,
    activationKey,
    badActivationKey,
}) {
    // If a bad key, redirect to login page
    if (badActivationKey) {
        return <Redirect to="/login" />;
    }

    // Activation succeeded, redirect to home page
    if (activationStatus === loadingStatus.SUCCEEDED) {
        return <Redirect to="/" />;
    }

    // Adds the activation key to the form submission
    const onFormSubmit = ({ confirmation, password }) =>
        onActivationSubmit({
            confirmation,
            password,
            activationKey,
        });

    return (
        <div className="registration-container">
            <div className="header">
                <span className="card-title">Set new password</span>
            </div>
            <hr />
            <ActivationAlert
                status={activationStatus}
                confirmation={confirmation}
                password={password}
            />
            <div className="registration-form-container">
                <ActivationForm
                    onChange={onCredentialChange}
                    onSubmit={onFormSubmit}
                    confirmation={confirmation}
                    password={password}
                    disabled={activationStatus === loadingStatus.STARTED}
                />
            </div>
        </div>
    );
}

ActivationFormContainer.propTypes = {
    onActivationSubmit: PropTypes.func.isRequired,
    onCredentialChange: PropTypes.func.isRequired,
    confirmation: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    activationStatus: PropTypes.string,
    badActivationKey: PropTypes.bool.isRequired,
    activationKey: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        confirmation: state.auth.activationCredentials.confirmation,
        password: state.auth.activationCredentials.password,
        activationStatus: state.auth.activationStatus,
        badActivationKey: loadingUnsuccessful(state.auth.checkActivationStatus),
        activationKey: state.auth.activationKey,
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            onActivationSubmit: actions.activateUser,
            onCredentialChange: actions.updateActivationCredentials,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivationFormContainer);
