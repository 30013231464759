import * as types from '../constants/action-types';
import * as loadingStatus from '../constants/loading-status';

const initialState = {
    requested: false,
    preference: {
        touched: false,
        brand: undefined,
        program: undefined,
        consentType: undefined,
        channel: undefined,
        consentStatus: undefined,
        expiryDate: undefined,
        serviceName: undefined,
    },
    config: {
        consentStatuses: ['granted', 'revoked'],
        consentTypesRequiringChannel: ['marketing', 'tcpa'],
        consentTypesRequiringExpiry: ['hipaa'],
    },
    submitting: false,
};

export default function newConsentPreferenceReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case types.NEW_CONSENT_PREFERENCE_REQUEST:
            return {
                ...state,
                preference: initialState.preference,
                requested: true,
            };
        case types.NEW_CONSENT_PREFERENCE_CLEAR:
            return {
                ...state,
                preference: initialState.preference,
                requested: false,
            };
        case types.NEW_CONSENT_PREFERENCE_UPDATED:
            return {
                ...state,
                preference: {
                    ...state.preference,
                    touched: true,
                    expiryDate:
                        action.key === 'consentStatus' &&
                        action.value === 'revoked'
                            ? ''
                            : state.preference.expiryDate,
                    [action.key]:
                        action.key === 'expiryDate' &&
                        state.preference.consentStatus === 'revoked'
                            ? state.preference.expiryDate
                            : action.value,
                },
            };
        case types.NEW_CONSENT_PREFERENCE_SUBMIT_STATUS:
            return {
                ...state,
                submitting: action.status === loadingStatus.STARTED,
            };
        default:
            return state;
    }
}
