import * as types from '../constants/action-types';
import {
    consenterWithNewlyTransactedPreferences,
    submitConsentPreferences,
} from '../utils/backend';
import * as loadingStatus from '../constants/loading-status';

export const requestNewConsentPreference = () => ({
    type: types.NEW_CONSENT_PREFERENCE_REQUEST,
});

export const clearNewConsentPreference = () => ({
    type: types.NEW_CONSENT_PREFERENCE_CLEAR,
});

export const updateNewConsentPreference = (key, value) => ({
    type: types.NEW_CONSENT_PREFERENCE_UPDATED,
    key,
    value,
});

export const submitNewConsentPreference = (
    consenter,
    currentUserEmail,
    newPreference
) => async dispatch => {
    dispatch({
        type: types.NEW_CONSENT_PREFERENCE_SUBMIT_STATUS,
        status: loadingStatus.STARTED,
    });
    try {
        const transaction = await submitConsentPreferences(
            consenter,
            currentUserEmail,
            [newPreference]
        );
        const freshConsenter = await consenterWithNewlyTransactedPreferences(
            transaction,
            consenter.consenter_id,
            consenter.consenter_type
        );
        dispatch({
            type: types.LOAD_CONSENTER,
            consenter: freshConsenter,
        });
        dispatch({
            type: types.NEW_CONSENT_PREFERENCE_SUBMIT_STATUS,
            status: loadingStatus.SUCCEEDED,
        });
        dispatch({ type: types.NEW_CONSENT_PREFERENCE_CLEAR });
    } catch (error) {
        dispatch({
            type: types.NEW_CONSENT_PREFERENCE_SUBMIT_STATUS,
            status: loadingStatus.FAILED,
        });
    }
};
