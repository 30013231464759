import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import FilteredCandidatesModal from './FilteredCandidatesModal';

function FilteredCandidatesModalContainer({
    filteredCandidates,
    filteringStatus,
}) {
    return (
        <FilteredCandidatesModal
            filteredCandidates={filteredCandidates}
            filteringStatus={filteringStatus}
        />
    );
}
FilteredCandidatesModalContainer.propTypes = {
    filteredCandidates: PropTypes.array,
    filteringStatus: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
    return {
        filteredCandidates:
            state.aggregateStats.consenters.filtered_consenters.value,
        filteringStatus:
            state.aggregateStats.consenters.filtered_consenters.status,
    };
}
export default connect(mapStateToProps)(FilteredCandidatesModalContainer);
