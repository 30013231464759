import React from 'react';
import PropTypes from 'prop-types';
import {
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table,
} from 'reactstrap';
import {
    formatConsentTypeText,
    prettifyConsenterTypeText,
} from '../../../../../../../utils/view-helpers';
import MergeIdList from '../MergeIdList';
import DatePicker from 'react-datepicker';
import LoadingSpinner from '../../../../../shared/LoadingSpinner';

/**
 * A Modal that displays a form for a proposed new consent preference.
 *
 * @param props
 * @returns {*}
 */
export default function AddNewConsentPreferenceModal({
    consenter,
    currentUserEmail,
    newConsentPreferenceRequested,
    newConsentPreference: {
        touched,
        brand,
        program,
        consentType,
        channel,
        consentStatus,
        expiryDate,
        serviceName,
    },
    newConsentPreference,
    knownBrandsAndProgramsAndServiceNames: { brands, programs, serviceNames },
    newConsentPreferenceConfig: {
        channels,
        consentTypes,
        consentStatuses,
        consentTypesRequiringChannel,
        consentTypesRequiringExpiry,
    },
    clearNewConsentPreference,
    updateNewConsentPreference,
    submitNewConsentPreference,
    newConsentPreferenceSubmitting,
}) {
    const consenterId = consenter && consenter.consenter_id;
    const consenterType = consenter && consenter.consenter_type;

    const {
        consentPreferenceInvalid,
        preferenceErrors,
    } = validateConsentPreference(
        newConsentPreference,
        consentTypesRequiringChannel,
        consentTypesRequiringExpiry
    );

    const displayService = consentType === 'service' && serviceNames.length > 0;
    return (
        <Modal size="xl" isOpen={consenter && newConsentPreferenceRequested}>
            <ModalHeader toggle={clearNewConsentPreference}>
                {prettifyConsenterTypeText(consenterType)} {consenterId} - add
                new consent preferences
                <MergeIdList consenter={consenter} />
            </ModalHeader>
            <ModalBody>
                <Form className="consent-preference-form">
                    <Table
                        responsive
                        className="table table-bordered table-striped consent-preference-table"
                    >
                        <thead>
                            <tr>
                                <th>Brand</th>
                                <th>Program</th>
                                <th>Consent type</th>
                                {displayService && <th>Service</th>}
                                <th>Channel</th>
                                <th>Consent status</th>
                                <th>Expiration</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {/* Brand */}
                                <DropdownFormGroupTableCell
                                    value={brand}
                                    fieldName="brand"
                                    onChange={updateNewConsentPreference}
                                    values={brands}
                                    errors={preferenceErrors}
                                />
                                {/* Program */}
                                <DropdownFormGroupTableCell
                                    value={program}
                                    fieldName="program"
                                    onChange={updateNewConsentPreference}
                                    values={programs}
                                    errors={preferenceErrors}
                                />
                                {/* Consent type */}
                                <DropdownFormGroupTableCell
                                    value={consentType}
                                    fieldName="consentType"
                                    onChange={updateNewConsentPreference}
                                    values={consentTypes}
                                    errors={preferenceErrors}
                                />
                                {/* Service */}
                                {displayService && (
                                    <DropdownFormGroupTableCell
                                        value={serviceName}
                                        fieldName="serviceName"
                                        onChange={updateNewConsentPreference}
                                        values={serviceNames}
                                        initialValue={{
                                            display: '(None)',
                                            value: '',
                                            disabled: false,
                                        }}
                                    />
                                )}
                                {/* Channel */}
                                <DropdownFormGroupTableCell
                                    value={channel}
                                    fieldName="channel"
                                    onChange={updateNewConsentPreference}
                                    values={channels}
                                    initialValue={{
                                        display: '(None)',
                                        value: '',
                                        disabled: false,
                                    }}
                                    errors={preferenceErrors}
                                />
                                {/* Consent status */}
                                <DropdownFormGroupTableCell
                                    value={consentStatus}
                                    fieldName="consentStatus"
                                    onChange={updateNewConsentPreference}
                                    values={consentStatuses}
                                    errors={preferenceErrors}
                                />
                                {/* Expiration */}
                                <td>
                                    <FormGroup>
                                        <Input
                                            invalid={Boolean(
                                                preferenceErrors.expiryDate
                                            )}
                                            type="hidden"
                                        />
                                        <DatePicker
                                            disabled={
                                                consentStatus === 'revoked'
                                            }
                                            selected={
                                                consentStatus === 'revoked'
                                                    ? ''
                                                    : expiryDate
                                            }
                                            value={
                                                consentStatus === 'revoked'
                                                    ? 'N/A'
                                                    : expiryDate
                                            }
                                            onChange={updateNewConsentPreference.bind(
                                                null,
                                                'expiryDate'
                                            )}
                                            className={
                                                preferenceErrors.expiryDate
                                                    ? 'invalid'
                                                    : ''
                                            }
                                            minDate={new Date()}
                                        />
                                        <FormFeedback>
                                            {preferenceErrors.expiryDate}
                                        </FormFeedback>
                                    </FormGroup>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Form>
                <Modal isOpen={newConsentPreferenceSubmitting}>
                    <ModalHeader>Adding new consent preference…</ModalHeader>
                    <ModalBody>
                        <LoadingSpinner
                            isLoading={newConsentPreferenceSubmitting}
                            component={() => null}
                        />
                    </ModalBody>
                </Modal>
            </ModalBody>
            <ModalFooter>
                <button
                    onClick={() =>
                        touched
                            ? submitNewConsentPreference(
                                  consenter,
                                  currentUserEmail,
                                  newConsentPreference
                              )
                            : updateNewConsentPreference('touched', true)
                    }
                    disabled={consentPreferenceInvalid}
                    style={{ width: '100px' }}
                    className="standard text-normal green"
                >
                    Add
                </button>
            </ModalFooter>
        </Modal>
    );
}

AddNewConsentPreferenceModal.propTypes = {
    consenter: PropTypes.object,
    currentUserEmail: PropTypes.string.isRequired,
    newConsentPreference: PropTypes.object.isRequired,
    newConsentPreferenceRequested: PropTypes.bool.isRequired,
    knownBrandsAndProgramsAndServiceNames: PropTypes.shape({
        brands: PropTypes.arrayOf(PropTypes.string).isRequired,
        programs: PropTypes.arrayOf(PropTypes.string).isRequired,
        serviceNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    newConsentPreferenceConfig: PropTypes.object.isRequired,
    updateNewConsentPreference: PropTypes.func.isRequired,
    clearNewConsentPreference: PropTypes.func.isRequired,
    submitNewConsentPreference: PropTypes.func.isRequired,
    newConsentPreferenceSubmitting: PropTypes.bool.isRequired,
};

/**
 * Return a map containing two keys:
 * - consentPreferenceInvalid: Whether or not the entire proposed consent preference is valid to be submitted
 * - preferenceErrors: A a map of “validations” against the proposed consent preference passed to this function.
 *   Validations are either boolean false (not invalid) or a string value containing feedback for the user
 *   about why the particular field was invalid.
 *
 * @param {string}   brand
 * @param {string}   program
 * @param {string}   consentType
 * @param {string}   channel
 * @param {string}   consentStatus
 * @param {string}   expiryDate
 * @param {boolean}  touched
 * @param {string[]} consentTypesRequiringChannel
 * @param {string[]} consentTypesRequiringExpiry
 * @returns {{
 *      consentPreferenceInvalid: boolean
 *      preferenceErrors: {
 *          expiryDate: string|false,
 *          brand: string|false,
 *          program: string|false,
 *          consentStatus: string|false,
 *          channel: string|false,
 *          consentType: string|false
 *      }
 * }}
 */
function validateConsentPreference(
    {
        brand,
        program,
        consentType,
        channel,
        consentStatus,
        expiryDate,
        touched,
    },
    consentTypesRequiringChannel,
    consentTypesRequiringExpiry
) {
    const brandInvalid = !touched || brand ? false : 'Please select a brand.';
    const programInvalid =
        !touched || program ? false : 'Please select a program.';
    const consentTypeInvalid =
        !touched || consentType ? false : 'Please select a consent type.';
    const consentStatusInvalid =
        !touched || consentStatus ? false : 'Please select a consent status.';

    const channelInvalid =
        touched &&
        !channel &&
        consentTypesRequiringChannel.includes(consentType)
            ? `Channel is required for consent type ${formatConsentTypeText(
                  consentType
              )}.`
            : false;

    const expiryDateInvalid =
        touched &&
        !expiryDate &&
        consentTypesRequiringExpiry.includes(consentType) &&
        consentStatus === 'granted'
            ? `Expiration is required when granting ${formatConsentTypeText(
                  consentType
              )} consent.`
            : false;

    const consentPreferenceInvalid = Boolean(
        brandInvalid ||
            programInvalid ||
            consentTypeInvalid ||
            channelInvalid ||
            consentStatusInvalid ||
            expiryDateInvalid
    );

    return {
        consentPreferenceInvalid,
        preferenceErrors: {
            brand: brandInvalid,
            program: programInvalid,
            consentType: consentTypeInvalid,
            channel: channelInvalid,
            consentStatus: consentStatusInvalid,
            expiryDate: expiryDateInvalid,
        },
    };
}

/**
 * A helper component to reduce duplicated markup.
 *
 * @param {string} fieldName
 * @param {string} value
 * @param {function} onChange
 * @param {string[]} values
 * @param {*} errors
 * @param {{ display: string, value: string, disabled: boolean }} initialValue
 * @returns {*}
 */
function DropdownFormGroupTableCell({
    fieldName,
    value,
    onChange,
    values,
    errors,
    initialValue = { display: 'Select', value: undefined, disabled: true },
}) {
    const invalid = (errors && errors[fieldName]) || false;
    return (
        <td>
            <FormGroup>
                <Input
                    invalid={Boolean(invalid)}
                    type="select"
                    bsSize="sm"
                    value={value}
                    onChange={e => onChange(fieldName, e.target.value)}
                >
                    <option
                        disabled={initialValue.disabled}
                        selected={!value}
                        value={initialValue.value}
                    >
                        {initialValue.display}
                    </option>
                    {values.map((v, i) => (
                        <option key={i} value={v}>
                            {formatConsentTypeText(v)}
                        </option>
                    ))}
                </Input>
                <FormFeedback>{invalid}</FormFeedback>
            </FormGroup>
        </td>
    );
}

DropdownFormGroupTableCell.propTypes = {
    fieldName: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    values: PropTypes.arrayOf(PropTypes.any).isRequired,
    errors: PropTypes.exact({
        brand: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        channel: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        consentStatus: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        consentType: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        expiryDate: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        program: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    }),
    initialValue: PropTypes.shape({
        display: PropTypes.string,
        value: PropTypes.any,
        disabled: PropTypes.bool,
    }),
};
