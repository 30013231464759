import * as types from '../constants/action-types';
import { createLoadingAction } from './action-helpers';
import { loadFilteredConsenters } from './aggregate-stats-actions';
import { getFilteredGlobalOptOuts } from '../utils/backend';

/**
 * Loading actions for loading Total Consenter Stats
 */
export const loadTotalConsentersGranted = loadFilteredConsenters(
    'granted',
    types.LOAD_TOTAL_CONSENTERS_GRANTED,
    types.LOAD_TOTAL_CONSENTERS_GRANTED_STATUS
);
export const loadTotalConsentersRevoked = loadFilteredConsenters(
    'revoked',
    types.LOAD_TOTAL_CONSENTERS_REVOKED,
    types.LOAD_TOTAL_CONSENTERS_REVOKED_STATUS
);
export const loadTotalConsentersUnknown = loadFilteredConsenters(
    'unknown',
    types.LOAD_TOTAL_CONSENTERS_UNKNOWN,
    types.LOAD_TOTAL_CONSENTERS_UNKNOWN_STATUS
);

/**
 * Load Total Global-Opt-Out Stats
 */
export const loadTotalGlobalOptOuts = createLoadingAction(
    types.LOAD_TOTAL_GLOBAL_OPT_OUTS_STATUS,
    () => async dispatch => {
        const total = await getFilteredGlobalOptOuts({});
        dispatch({
            type: types.LOAD_TOTAL_GLOBAL_OPT_OUTS,
            global_opt_outs: total,
        });
    }
);

/**
 * Loads total consenter stats
 */
export const loadTotalStats = () => async dispatch => {
    loadTotalConsentersGranted({})(dispatch);
    loadTotalConsentersRevoked({})(dispatch);
    loadTotalConsentersUnknown({})(dispatch);
    loadTotalGlobalOptOuts({})(dispatch);
};
