import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav } from 'reactstrap';

import SearchbarContainer from '../SearchBar';
import MenuContainer from '../Menu';

const logo = require('../../../shared/static/hv_consent.svg');

export default class NavigationBar extends React.Component {
    constructor(props) {
        super(props);

        this.toggleMenu = this.toggleMenu.bind(this);
        this.state = {
            menu: false,
        };
    }

    toggleMenu() {
        this.setState(prevState => ({ menu: !prevState.menu }));
    }

    renderMenuContainer() {
        return (
            this.props.isLoggedIn && (
                <MenuContainer
                    isOpen={this.state.menu}
                    onToggle={this.toggleMenu}
                />
            )
        );
    }

    renderMenuToggleButton() {
        return (
            this.props.isLoggedIn && (
                <NavbarToggler
                    className="menu-button"
                    onClick={this.toggleMenu}
                />
            )
        );
    }

    render() {
        return (
            <div>
                {this.renderMenuContainer()}
                <Navbar className="navigation-bar" light expand="md">
                    {this.renderMenuToggleButton()}
                    <NavbarBrand className="logo">
                        <img src={logo} />
                    </NavbarBrand>
                    <Collapse isOpen={true} navbar>
                        <Nav className="ml-auto" navbar>
                            <SearchbarContainer />
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}

NavigationBar.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
};
