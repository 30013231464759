import React from 'react';
import PropTypes from 'prop-types';
import {
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table,
} from 'reactstrap';
import {
    formatConsentTypeText,
    getPreferenceCompositeKey,
    prettifyConsenterTypeText,
} from '../../../../../../../utils/view-helpers';
import MergeIdList from '../MergeIdList';
import LoadingSpinner from '../../../../../shared/LoadingSpinner';
import groupBy from 'lodash.groupby';
import DatePicker from 'react-datepicker';

export default function UpdateConsentPreferencesModal({
    consenter,
    currentUserEmail,
    preferencesBeingUpdated,
    updateConsentPreferencesRequested,
    updateConsentPreferencesConfig: {
        consentStatuses,
        consentTypesRequiringExpiry,
    },
    updateConsentPreferenceConsentStatus,
    updateConsentPreferenceExpiryDate,
    clearUpdatedConsentPreferences,
    submitUpdatedConsentPreferences,
    updatedConsentPreferencesSubmitting,
}) {
    const consenterId = consenter && consenter.consenter_id;
    const consenterType = consenter && consenter.consenter_type;
    const preferences = (consenter && consenter.preferences) || [];

    const noPreferencesWereUpdated =
        Object.keys(preferencesBeingUpdated) < 1 ||
        preferences
            .map(p => [
                p,
                preferencesBeingUpdated[getPreferenceCompositeKey(p)],
            ])
            .every(([original, beingUpdated]) => {
                const { status, expiry_date } = original;
                return (
                    !beingUpdated ||
                    (beingUpdated.consentStatus === status &&
                        beingUpdated.expiryDate !== expiry_date)
                );
            });

    return (
        <Modal
            size="xl"
            isOpen={consenter && updateConsentPreferencesRequested}
        >
            <ModalHeader toggle={clearUpdatedConsentPreferences}>
                {prettifyConsenterTypeText(consenterType)} {consenterId} -
                update consent preferences
                <MergeIdList consenter={consenter} />
            </ModalHeader>
            <ModalBody>
                <Table
                    responsive
                    className="table table-bordered table-striped consent-preference-table"
                >
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Program</th>
                            <th>Consent type</th>
                            <th>Channel</th>
                            <th>Consent status</th>
                            <th>Expiration</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mapPreferencesToRows(
                            preferences,
                            preferencesBeingUpdated,
                            consentStatuses,
                            updateConsentPreferenceConsentStatus,
                            updateConsentPreferenceExpiryDate,
                            consentTypesRequiringExpiry
                        )}
                    </tbody>
                </Table>
                <Modal isOpen={updatedConsentPreferencesSubmitting}>
                    <ModalHeader>Updating consent preferences…</ModalHeader>
                    <ModalBody>
                        <LoadingSpinner
                            isLoading={updatedConsentPreferencesSubmitting}
                            component={() => null}
                        />
                    </ModalBody>
                </Modal>
            </ModalBody>
            <ModalFooter>
                <button
                    onClick={() =>
                        submitUpdatedConsentPreferences(
                            consenter,
                            currentUserEmail,
                            preferencesBeingUpdated
                        )
                    }
                    disabled={noPreferencesWereUpdated}
                    style={{ width: '100px' }}
                    className="standard text-normal green"
                >
                    Save
                </button>
            </ModalFooter>
        </Modal>
    );
}

UpdateConsentPreferencesModal.propTypes = {
    consenter: PropTypes.object,
    currentUserEmail: PropTypes.string.isRequired,
    preferencesBeingUpdated: PropTypes.object.isRequired,
    updateConsentPreferencesRequested: PropTypes.bool.isRequired,
    updateConsentPreferencesConfig: PropTypes.object.isRequired,
    updateConsentPreferenceConsentStatus: PropTypes.func.isRequired,
    updateConsentPreferenceExpiryDate: PropTypes.func.isRequired,
    clearUpdatedConsentPreferences: PropTypes.func.isRequired,
    submitUpdatedConsentPreferences: PropTypes.func.isRequired,
    updatedConsentPreferencesSubmitting: PropTypes.bool.isRequired,
};

function mapPreferencesToRows(
    preferences,
    preferencesBeingUpdated,
    consentStatuses,
    updateConsentPreferenceConsentStatus,
    updateConsentPreferenceExpiryDate,
    consentTypesRequiringExpiry
) {
    const data = groupTableData(preferences);
    const rows = [];
    Object.entries(data)
        .sort(pairsByKey)
        .forEach(([brand, brandRow]) => {
            let cells = [];
            cells.push(
                <RowSpanningCell
                    key={cells.length}
                    data={brand}
                    rowSpan={preferencesPerBrand(brandRow)}
                />
            );
            Object.entries(brandRow)
                .sort(pairsByKey)
                .forEach(([program, programRow]) => {
                    cells.push(
                        <RowSpanningCell
                            key={cells.length}
                            data={program}
                            rowSpan={preferencesPerProgram(programRow)}
                        />
                    );
                    Object.entries(programRow)
                        .sort(pairsByKey)
                        .forEach(([consentType, consentTypeRow]) => {
                            cells.push(
                                <RowSpanningCell
                                    key={cells.length}
                                    data={consentType}
                                    rowSpan={preferencesPerConsentType(
                                        consentTypeRow
                                    )}
                                />
                            );
                            consentTypeRow.forEach(preference => {
                                const {
                                    channel,
                                    consent_type: consentType,
                                } = preference;
                                const preferenceCompositeKey = getPreferenceCompositeKey(
                                    preference
                                );
                                const {
                                    consentStatus,
                                    expiryDate,
                                } = preferencesBeingUpdated[
                                    preferenceCompositeKey
                                ] || {
                                    expiryDate: preference.expiry_date,
                                    consentStatus: preference.status,
                                };
                                const expiryDateInvalid =
                                    !expiryDate &&
                                    consentTypesRequiringExpiry.includes(
                                        consentType
                                    ) &&
                                    consentStatus === 'granted';
                                cells.push(
                                    <ChannelCell
                                        key={cells.length}
                                        channel={channel}
                                    />
                                );
                                cells.push(
                                    <ConsentStatusInputCell
                                        key={cells.length}
                                        consentStatuses={consentStatuses}
                                        selectedConsentStatus={consentStatus}
                                        onConsentStatusChange={e =>
                                            updateConsentPreferenceConsentStatus(
                                                preferenceCompositeKey,
                                                e.target.value
                                            )
                                        }
                                    />
                                );
                                cells.push(
                                    <ExpirationDateInputCell
                                        key={cells.length}
                                        invalid={Boolean(expiryDateInvalid)}
                                        consentType={consentType}
                                        consentStatus={consentStatus}
                                        expiryDate={expiryDate}
                                        onExpiryDateChange={newExpiryDate =>
                                            updateConsentPreferenceExpiryDate(
                                                preferenceCompositeKey,
                                                newExpiryDate
                                            )
                                        }
                                    />
                                );
                                rows.push(<tr key={rows.length}>{cells}</tr>);
                                cells = [];
                            });
                        });
                });
        });
    return rows;
}

function pairsByKey([a], [b]) {
    return a.localeCompare(b);
}

function preferencesPerBrand(brand) {
    return Object.values(brand)
        .map(preferencesPerProgram)
        .reduce(add);
}

function preferencesPerProgram(program) {
    return Object.values(program)
        .map(preferencesPerConsentType)
        .reduce(add);
}

function preferencesPerConsentType(preferences) {
    return preferences.length;
}

function add(x, y) {
    return x + y;
}

function RowSpanningCell({ data, rowSpan }) {
    return (
        <td className="table-light" rowSpan={rowSpan}>
            {formatConsentTypeText(data)}
        </td>
    );
}

RowSpanningCell.propTypes = {
    data: PropTypes.string.isRequired,
    rowSpan: PropTypes.number.isRequired,
};

function ChannelCell({ channel }) {
    return <td>{formatConsentTypeText(channel) || 'n/a'}</td>;
}

ChannelCell.propTypes = {
    channel: PropTypes.string,
};

function ConsentStatusInputCell({
    consentStatuses,
    selectedConsentStatus,
    onConsentStatusChange,
}) {
    return (
        <td>
            <Input
                type="select"
                bsSize="sm"
                value={selectedConsentStatus}
                onChange={onConsentStatusChange}
            >
                {consentStatuses.map((v, i) => (
                    <option key={i} value={v} disabled={v === 'expired'}>
                        {formatConsentTypeText(v)}
                    </option>
                ))}
            </Input>
        </td>
    );
}

ConsentStatusInputCell.propTypes = {
    consentStatuses: PropTypes.arrayOf(
        PropTypes.oneOf(['granted', 'revoked', 'expired'])
    ).isRequired,
    selectedConsentStatus: PropTypes.oneOf(['granted', 'revoked', 'expired'])
        .isRequired,
    onConsentStatusChange: PropTypes.func.isRequired,
};

function ExpirationDateInputCell({
    invalid,
    consentType,
    consentStatus,
    expiryDate,
    onExpiryDateChange,
}) {
    const disabled = consentStatus === 'revoked';
    const selected =
        (consentStatus !== 'revoked' && expiryDate && new Date(expiryDate)) ||
        '';
    const value = disabled ? 'N/A' : (expiryDate && new Date(expiryDate)) || '';
    const minDate = new Date();
    const formattedConsentType = formatConsentTypeText(consentType);
    return (
        <td>
            <Form className="consent-preference-form">
                <FormGroup>
                    <Input invalid={invalid} type="hidden" />
                    <DatePicker
                        disabled={disabled}
                        selected={selected}
                        value={value}
                        onChange={onExpiryDateChange}
                        minDate={minDate}
                    />
                    <FormFeedback>
                        Expiration is required when granting{' '}
                        {formattedConsentType} consent.
                    </FormFeedback>
                </FormGroup>
            </Form>
        </td>
    );
}

ExpirationDateInputCell.propTypes = {
    invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    consentType: PropTypes.string.isRequired,
    consentStatus: PropTypes.oneOf(['granted', 'revoked', 'expired'])
        .isRequired,
    expiryDate: PropTypes.string,
    onExpiryDateChange: PropTypes.func.isRequired,
};

/**
 *  Takes a consenter preferences list and returns a deeply-nested consent preferences
 *  object with each consent preference indexed by consent type, each consent type
 *  indexed by program name, and each program name indexed by brand at the top level.
 *
 * @param {object[]} preferences
 * @returns {{}}
 */
function groupTableData(preferences) {
    const preferencesByBrand = groupBy(preferences, 'brand');
    return Object.entries(preferencesByBrand)
        .map(([brand, byBrandAsList]) => {
            const preferencesByProgram = groupBy(byBrandAsList, 'program_name');
            const byProgramAsMap = Object.entries(preferencesByProgram)
                .map(([program, byProgramAsList]) => {
                    const keySelector = ({ consent_type, service_name }) =>
                        consent_type === 'service' && service_name
                            ? `Service - ${service_name}`
                            : consent_type;
                    const byConsentTypeAsList = groupBy(
                        byProgramAsList,
                        keySelector
                    );
                    return [program, byConsentTypeAsList];
                })
                .reduce(intoObject, {});
            return [brand, byProgramAsMap];
        })
        .reduce(intoObject, {});
}

function intoObject(acc, [k, v]) {
    return { ...acc, [k]: v };
}
