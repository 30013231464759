import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as updateConsentPreferencesActions from '../../../../../../../actions/update-consent-preferences-actions';
import UpdateConsentPreferencesModal from './UpdateConsentPreferencesModal';

class UpdateConsentPreferencesModalContainer extends React.Component {
    componentDidMount() {
        this.syncConsentPreferenceRequestToLocation();
    }

    componentDidUpdate(prevProps) {
        const { updateConsentPreferencesRequested: prevRequested } = prevProps;
        const { updateConsentPreferencesRequested: currRequested } = this.props;

        if (!prevRequested && currRequested) {
            this.props.history.push(
                `${this.props.match.url}/update-consent-preferences`
            );
        } else if (prevRequested && !currRequested) {
            this.props.history.push(this.props.match.url);
        }
    }

    syncConsentPreferenceRequestToLocation() {
        if (
            this.props.location.pathname.endsWith('update-consent-preferences')
        ) {
            this.props.requestUpdateConsentPreferences();
        } else {
            this.props.clearUpdatedConsentPreferences();
        }
    }

    render() {
        return (
            this.props.hasWritePermission && (
                <UpdateConsentPreferencesModal {...this.props} />
            )
        );
    }
}

UpdateConsentPreferencesModalContainer.propTypes = {
    consenter: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    preferencesBeingUpdated: PropTypes.object.isRequired,
    updateConsentPreferencesRequested: PropTypes.bool.isRequired,
    currentUserEmail: PropTypes.string.isRequired,
    updateConsentPreferencesConfig: PropTypes.object.isRequired,
    updateConsentPreferenceConsentStatus: PropTypes.func.isRequired,
    updateConsentPreferenceExpiryDate: PropTypes.func.isRequired,
    clearUpdatedConsentPreferences: PropTypes.func.isRequired,
    submitUpdatedConsentPreferences: PropTypes.func.isRequired,
    updatedConsentPreferencesSubmitting: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    return {
        currentUserEmail: state.auth.currentUser.user.email,
        updateConsentPreferencesConfig: state.updateConsentPreferences.config,
        preferencesBeingUpdated: state.updateConsentPreferences.preferences,
        updatedConsentPreferencesSubmitting:
            state.updateConsentPreferences.submitting,
        hasWritePermission: state.auth.hasWritePermission,
    };
}

function mapDispatchToProps(dispatch) {
    const actionCreators = {
        updateConsentPreferenceConsentStatus:
            updateConsentPreferencesActions.updateConsentPreferenceConsentStatus,
        updateConsentPreferenceExpiryDate:
            updateConsentPreferencesActions.updateConsentPreferenceExpiryDate,
        clearUpdatedConsentPreferences:
            updateConsentPreferencesActions.clearUpdatedConsentPreferences,
        submitUpdatedConsentPreferences:
            updateConsentPreferencesActions.submitUpdatedConsentPreferences,
    };
    return bindActionCreators(actionCreators, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateConsentPreferencesModalContainer);
