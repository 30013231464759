import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';

/**
 * Activation component
 * @param {string} options.confirmation The confirmation value of the user
 * @param {string} options.password The password value of the user
 * @param {Function} options.onSubmit Event handler for the form submission
 * @param {Function} options.onChange Event handler for confirmation/password updates
 * @param {Function} options.disable Whether to disable submit
 */
export default function ActivationForm({
    confirmation,
    password,
    onSubmit,
    onChange,
    disabled,
}) {
    /**
     * Whenever a field changes, call the onChange callback with the current
     * credentials, updated with the latest value
     */
    const onFieldChange = fieldName => evt =>
        onChange(
            Object.assign(
                { confirmation, password },
                { [fieldName]: evt.target.value }
            )
        );

    /**
     * Call the onSubmit callback with confirmation/password credentials,
     * only if the form is not disabled
     */
    const onFormSubmit = evt => {
        evt.preventDefault();
        if (!disabled) {
            onSubmit({ confirmation, password });
        }
        return false;
    };

    return (
        <Form className="registration-form" onSubmit={onFormSubmit}>
            <FormGroup>
                <Label for="activation-password">Password</Label>
                <Input
                    type="password"
                    id="activation-password"
                    onChange={onFieldChange('password')}
                />
            </FormGroup>
            <FormGroup>
                <Label for="activation-confirmation">
                    Password Confirmation
                </Label>
                <Input
                    type="password"
                    id="activation-confirmation"
                    onChange={onFieldChange('confirmation')}
                />
            </FormGroup>
            <Button className="hv-btn-gr-green float-right" disabled={disabled}>
                Submit
            </Button>
        </Form>
    );
}

ActivationForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    confirmation: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
};
