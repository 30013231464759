/**
 * A module that exposes common logic for digesting current state
 */

import * as loadingStatus from '../../../constants/loading-status';

/**
 * Given a loading status, determins if the load is complete
 * @param  {string} status  The current loading status
 * @return {boolean}        Whether loading is complete
 */
export function loadingComplete(status) {
    return Boolean(status && status !== loadingStatus.STARTED);
}

/**
 * Given a loading status, determine if the load is complete, but did not
 * succeed
 * @param  {string} status  The current loading status
 * @return {boolean}        Whether it load was unsuccessful
 */
export function loadingUnsuccessful(status) {
    return loadingComplete(status) && status !== loadingStatus.SUCCEEDED;
}

/**
 * Given a loading status, deterimine if the load is in progress
 */
export function loadingInProgress(status) {
    return status === loadingStatus.STARTED;
}

/**
 * Given one or more loading status, check if any are in progress
 */
export function anyInProgress(...statuses) {
    return statuses.some(loadingInProgress);
}
