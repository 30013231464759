import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';

import * as loadingStatus from '../../../../../constants/loading-status';

import '../../../../../styles/export-modal.scss';
import './filtered-candidates.scss';

export default class FilteredCandidatesModal extends React.Component {
    renderCandidateIdsContent() {
        let mainContent =
            'No candidate IDs were given to filter on, please try again.';
        let tableContent = null;
        switch (this.props.filteringStatus) {
            case loadingStatus.STARTED:
                mainContent = 'Loading...';
                break;
            case loadingStatus.SUCCEEDED:
                if (this.props.filteredCandidates.length === 0) {
                    mainContent = 'None of the IDs given match the criteria.';
                    break;
                }
                mainContent = 'The following IDs match the given criteria:';
                tableContent = (
                    <table className="candidate-ids-table">
                        {this.props.filteredCandidates.map(id => {
                            return (
                                <tr key={id}>
                                    <td>{id}</td>
                                </tr>
                            );
                        })}
                    </table>
                );
                break;
            case loadingStatus.FAILED:
            case loadingStatus.REJECTED:
                mainContent =
                    'Something went wrong when trying to determine ' +
                    'which IDs match the criteria. Please try again.';
                break;
        }
        return (
            <Alert color="success">
                <h5 className="candidate-ids-content">{mainContent}</h5>
                {tableContent}
            </Alert>
        );
    }

    render() {
        return (
            <Modal className="export-popup" size="sm" isOpen={true}>
                <ModalHeader>Filtered Candidate IDs</ModalHeader>
                <ModalBody>
                    {this.renderCandidateIdsContent()}
                    <Link to="/">
                        <button className="cancel">Cancel</button>
                    </Link>
                </ModalBody>
            </Modal>
        );
    }
}
FilteredCandidatesModal.propTypes = {
    filteredCandidates: PropTypes.array,
    filteringStatus: PropTypes.string.isRequired,
};
