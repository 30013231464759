import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, ListGroupItem, Row, Col } from 'reactstrap';
import LoadingSpinner from './LoadingSpinner';
import { formatNumber } from '../../../utils/view-helpers';
import { STARTED, FAILED, REJECTED } from '../../../constants/loading-status';

export function StatRow({ value, name, loadingStatus, onRetry }) {
    return (
        <ListGroupItem>
            {([REJECTED, FAILED].includes(loadingStatus) && (
                <span className="stat-loading-status">
                    <span
                        className="glyphicons glyphicons-repeat"
                        onClick={onRetry}
                    />
                    <span className="stat-retry">Retry</span>
                </span>
            )) || (
                <LoadingSpinner
                    isLoading={loadingStatus === STARTED}
                    component={() => (
                        <span>
                            <span className="stat-value stat-row-item">
                                {formatNumber(value)}
                            </span>
                            <span className="stat-name stat-row-item">
                                {name}
                            </span>
                        </span>
                    )}
                />
            )}
        </ListGroupItem>
    );
}

export function StatsTile(props) {
    let tileClicked = () => {};
    let selectedClass = 'selected';
    if (props.selectedTile !== props.tileName) {
        tileClicked = () => {
            props.onTileClicked(props.tileName);
        };
        selectedClass = 'unselected';
    }

    let classes = 'stats-tile ' + selectedClass;

    return (
        <Col>
            <div className={classes} onClick={tileClicked}>
                <Card>
                    <CardBody>
                        <CardTitle>
                            {props.description}
                            <span className="card-title-subtext">
                                {props.descriptionSubtext}
                            </span>
                        </CardTitle>
                        {props.children}
                    </CardBody>
                </Card>
                <div className="tile-arrow-down" />
            </div>
        </Col>
    );
}

export function StatsTileRow({ children }) {
    return <Row>{children}</Row>;
}

StatsTileRow.propTypes = {
    children: PropTypes.array.isRequired,
};

StatRow.propTypes = {
    value: PropTypes.number,
    name: PropTypes.string.isRequired,
    loadingStatus: PropTypes.string.isRequired,
    onRetry: PropTypes.func.isRequired,
};

StatsTile.propTypes = {
    selectedTile: PropTypes.string.isRequired,
    onTileClicked: PropTypes.func.isRequired,
    tileName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    descriptionSubtext: PropTypes.string,
    children: PropTypes.array.isRequired,
};
