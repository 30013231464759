import React from 'react';
import PropTypes from 'prop-types';

const crossIcon = require('./static/cross.svg');
const tickIcon = require('./static/tick.svg');

import '../../../styles/expiration-text.scss';

export default function StatusCell({ status }) {
    switch (status) {
        case 'granted':
            return (
                <span>
                    <img src={tickIcon} />
                </span>
            );
        case 'revoked':
            return (
                <span>
                    <img src={crossIcon} />
                </span>
            );
        case 'expired':
            return <span className="expiration-text">Expired</span>;
        case 'global opt out':
            return <span>Global Opt-outs</span>;
        default:
            if (status.includes('Expire')) {
                return <span>{status}</span>;
            }
            return <span>Unknown</span>;
    }
}

StatusCell.propTypes = {
    status: PropTypes.string.isRequired,
};
