import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupAddon, Input, Form } from 'reactstrap';

const searchIcon = require('../../../shared/static/search.svg');

export default function Searchbar(props) {
    if (!props.isLoggedIn) {
        return null;
    }

    /** Fire the onSubmit callback with the search value */
    const onSubmit = e => {
        e.preventDefault();
        if (props.value) {
            props.onSubmit(props.value);
        }
        return false;
    };

    /** Fire the onChange value if all characters are alphanumeric */
    const onValueChange = e => {
        const val = e.target.value;
        if (val.match(/^[a-zA-Z0-9]*$/)) {
            props.onChange(e.target.value);
        }
    };

    /** Submit form with the search value */
    return (
        <Form onSubmit={onSubmit} className="searchbar">
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <span
                        className="input-group-text pointer"
                        onClick={onSubmit}
                    >
                        <img src={searchIcon} />
                    </span>
                </InputGroupAddon>
                <Input
                    placeholder="Search - Patient / Provider ID"
                    value={props.value}
                    onChange={onValueChange}
                />
            </InputGroup>
        </Form>
    );
}

Searchbar.propTypes = {
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
};
