/**
 * Map various segments in a string, delimited by the chosen delimiter
 */
export const mapSegments = (delimiter, mapper) => str => {
    return str
        .split(delimiter)
        .map(mapper)
        .join(delimiter);
};

/**
 * Inits the first letter in a string and lowercases the rest of the string
 */
export const capitalize = value => {
    return (
        value &&
        value.length &&
        `${value[0].toUpperCase()}${value.substring(1).toLowerCase()}`
    );
};

/**
 * Inits the first letter in each word in a string, delimited by the given
 * delimiter
 */
export const initCap = (value, delimiter = ' ') => {
    return value && mapSegments(delimiter, capitalize)(value);
};

/**
 * Formats a number to the current locale
 */
export const formatNumber = num => {
    try {
        return new Intl.NumberFormat().format(num);
    } catch (err) {
        return num;
    }
};

/**
 * Formats a consent type string to uppercase or initcap depending on the value
 */
export const formatConsentTypeText = value => {
    switch (value) {
        case 'hipaa':
        case 'tcpa':
            return value.toUpperCase();
        default:
            return initCap(value);
    }
};

/**
 * Changes a consenter type string to a user-friendly label or capitalizes it,
 * depending on the value
 */
export const prettifyConsenterTypeText = value => {
    switch (value) {
        case 'hcp':
            return 'Provider';
        default:
            return capitalize(value);
    }
};

/**
 * Checks two strings for case insensitive equality
 * @param x
 * @param y
 * @returns boolean
 */
export const equalsIgnoreCase = (x, y) => {
    if (x === y) {
        return true;
    }

    return (x && x.toLowerCase()) === (y && y.toLowerCase());
};

/**
 * Return whether a preference constitutes a global opt-out
 * @param {string[]|null} brands
 * @param {string[]|null} program_names
 * @param {string[]|null} consent_types
 * @param {string[]|null} channels
 * @param {string} status
 * @returns {boolean}
 */
export const isGlobalOptOut = ({
    brands,
    program_names,
    consent_types,
    channels,
    status,
}) => {
    // An opt-out is global if it's revoked and not every one of the global opt-out fields has been specified
    return (
        status === 'revoked' &&
        !(brands && program_names && consent_types && channels)
    );
};

/**
 * Given a varargs array of strings, transform them and then join them together with '_'.
 * See func FormatCompositeKey in chaincode/utils.go
 * @param {...string} segments
 * @returns {string}
 */
export const formatCompositeKey = (...segments) => {
    return segments
        .map(x => (typeof x === 'string' ? x : ''))
        .map(s => s.replace(/[-_ ]/, ''))
        .map(s => s.trimRight()) // trimRight is an alias of trimEnd
        .map(s => s.toLowerCase())
        .join('_')
        .replace(/_+$/, '');
};

/**
 * Given a consent preference, return a string that uniquely identifies it, its "composite key".
 * See func ConsentPreferenceUpdate.CompositeKey in chaincode/consent_preference.go
 * @param {*} p - a consent preference
 * @returns {string}
 */
export const getPreferenceCompositeKey = p => {
    return formatCompositeKey(
        p.consentee_type,
        p.consentee_name,
        p.franchise,
        p.brand,
        p.program_name,
        p.service_name,
        p.consent_type,
        p.channel
    );
};
