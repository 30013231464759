import * as types from '../constants/action-types';

const initialState = {
    errorFileInfo: {},
    errorFileInfoStatus: null,
};

export default function aggregateStatsReducer(state = initialState, action) {
    switch (action.type) {
        case types.LOAD_ERROR_FILE_INFO:
            return Object.assign({}, state, {
                errorFileInfo: action.errorFileInfo,
            });
        case types.LOAD_ERROR_FILE_INFO_STATUS:
            return Object.assign({}, state, {
                errorFileInfoStatus: action.status,
            });
        default:
            return state;
    }
}
