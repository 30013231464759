import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import * as actions from '../../../../../actions/consenter-actions';
import Searchbar from './SearchBar';

class SearchbarContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Searchbar
                    isLoggedIn={this.props.isLoggedIn}
                    onChange={this.props.onConsenterIdChanged}
                    onSubmit={this.props.searchForConsenters}
                    value={this.props.consenterId}
                />
            </div>
        );
    }
}

SearchbarContainer.propTypes = {
    onConsenterIdChanged: PropTypes.func.isRequired,
    searchForConsenters: PropTypes.func.isRequired,
    consenterId: PropTypes.string.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        consenterId: state.consenter.consenterId,
        isLoggedIn: Boolean(state.auth.currentUser),
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            loadConsenter: actions.loadConsenter,
            onConsenterIdChanged: actions.updateConsenterId,
            searchForConsenters: actions.searchForConsenters,
        },
        dispatch
    );
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SearchbarContainer)
);
