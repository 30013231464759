import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../../../styles/expiration-text.scss';

export default function ExpiryDateCell({ preference }) {
    if (preference.status != 'expired') {
        if (preference.expiry_date) {
            return (
                <span>{moment(preference.expiry_date).format('M/D/YYYY')}</span>
            );
        } else {
            return <span>-</span>;
        }
    } else {
        return (
            <span className="expiration-text">
                {moment(preference.expiry_date).format('M/D/YYYY')}
            </span>
        );
    }
}

ExpiryDateCell.protoTypes = {
    preference: PropTypes.object.isRequired,
};
