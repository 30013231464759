import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { StatsTile, StatRow, StatsTileRow } from '../../../shared/StatsTile';
import DailyActivityTable from './components/DailyActivityTable';
import FilteredStatsTable from './components/FilteredStatsTable';
import { loadingInProgress } from '../../../shared/selectors';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../../actions/aggregate-stats-actions';
import * as exportActions from '../../../../../actions/export-actions';
import {
    TOTAL_CONSENTER_TILE,
    HISTORICAL_DATA_TILE,
} from '../../../../../constants/tile-names';

const today = moment().format('MMM DD, YYYY');

function HistoricalDataContent(props) {
    return (
        <div>
            <div className="activity-by-day">Activity by day</div>
            <LoadingSpinner
                component={DailyActivityTable}
                isLoading={props.historyIsLoading}
                dailyHistoryStats={props.dailyHistoryStats}
                isSuperuser={props.isSuperuser}
            />
        </div>
    );
}

HistoricalDataContent.propTypes = {
    dailyHistoryStats: PropTypes.array.isRequired,
    historyIsLoading: PropTypes.bool.isRequired,
    isSuperuser: PropTypes.bool.isRequired,
};

function AggregateStatsContainer(props) {
    let pageContent = (
        <FilteredStatsTable
            filterSelections={props.lastFilterSelections}
            consenters={props.consenters}
            onExport={props.onExport}
        />
    );
    if (props.selectedTile === HISTORICAL_DATA_TILE) {
        pageContent = (
            <HistoricalDataContent
                dailyHistoryStats={props.dailyHistoryStats}
                historyIsLoading={props.historyIsLoading}
                errorFileInfo={props.errorFileInfo}
                errorFileInfoIsLoading={props.errorFileInfoIsLoading}
                isSuperuser={props.isSuperuser}
            />
        );
    }

    const {
        startDate,
        endDate,
        filterSelections,
        onLoadDailyHistoryStatsRetry,
    } = props;
    const handleDailyHistoryRetry = () =>
        onLoadDailyHistoryStatsRetry(startDate, endDate, filterSelections);

    return (
        <div id="aggregate-stats-container">
            <StatsTileRow>
                <StatsTile
                    selectedTile={props.selectedTile}
                    onTileClicked={props.switchSelectedTile}
                    tileName={TOTAL_CONSENTER_TILE}
                    description="Total Consenters"
                    descriptionSubtext={`Updated - ${today}`}
                >
                    <StatRow
                        name={'Granted'}
                        value={props.totalConsenters.granted.value}
                        loadingStatus={props.totalConsenters.granted.status}
                        onRetry={() =>
                            props.onLoadFilteredConsentersGrantedRetry(
                                props.filterSelections
                            )
                        }
                    />
                    <StatRow
                        name={'Revoked'}
                        value={props.totalConsenters.revoked.value}
                        loadingStatus={props.totalConsenters.revoked.status}
                        onRetry={() =>
                            props.onLoadFilteredConsentersRevokedRetry(
                                props.filterSelections
                            )
                        }
                    />
                    <StatRow
                        name={'Unknown'}
                        value={props.totalConsenters.unknown.value}
                        loadingStatus={props.totalConsenters.unknown.status}
                        onRetry={() =>
                            props.onLoadFilteredConsentersUnknownRetry(
                                props.filterSelections
                            )
                        }
                    />
                    <StatRow
                        name={'Global Opt-outs'}
                        value={props.totalConsenters.global_opt_outs.value}
                        loadingStatus={
                            props.totalConsenters.global_opt_outs.status
                        }
                        onRetry={() =>
                            props.onLoadFilteredGlobalOptOutsRetry(
                                props.filterSelections
                            )
                        }
                    />
                </StatsTile>
                <StatsTile
                    selectedTile={props.selectedTile}
                    onTileClicked={props.switchSelectedTile}
                    tileName={HISTORICAL_DATA_TILE}
                    description="Historical Data"
                    descriptionSubtext={`${props.startDate} - ${props.endDate}`}
                >
                    <StatRow
                        name={'Granted'}
                        value={props.transactionTotals.granted}
                        loadingStatus={props.consenters.granted.status}
                        onRetry={handleDailyHistoryRetry}
                    />
                    <StatRow
                        name={'Revoked'}
                        value={props.transactionTotals.revoked}
                        loadingStatus={props.consenters.revoked.status}
                        onRetry={handleDailyHistoryRetry}
                    />
                    <StatRow
                        name={'Unknown'}
                        value={props.transactionTotals.unknown}
                        loadingStatus={props.consenters.unknown.status}
                        onRetry={handleDailyHistoryRetry}
                    />
                    <StatRow
                        name={'Global Opt-outs'}
                        value={props.transactionTotals['global opt-outs']}
                        loadingStatus={props.consenters.global_opt_outs.status}
                        onRetry={handleDailyHistoryRetry}
                    />
                </StatsTile>
            </StatsTileRow>
            <hr />
            {pageContent}
        </div>
    );
}

AggregateStatsContainer.propTypes = {
    transactionTotals: PropTypes.object.isRequired,
    totalConsenters: PropTypes.object.isRequired,
    consenters: PropTypes.object.isRequired,
    filterSelections: PropTypes.object.isRequired,
    metrics: PropTypes.array.isRequired,
    dailyHistoryStats: PropTypes.array.isRequired,
    errorFileInfo: PropTypes.object.isRequired,
    aggregatesAreLoading: PropTypes.bool.isRequired,
    errorFileInfoIsLoading: PropTypes.bool.isRequired,
    historyIsLoading: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    lastFilterSelections: PropTypes.object.isRequired,
    onLoadDailyHistoryStatsRetry: PropTypes.func.isRequired,
    onLoadFilteredConsentersGrantedRetry: PropTypes.func.isRequired,
    onLoadFilteredConsentersRevokedRetry: PropTypes.func.isRequired,
    onLoadFilteredConsentersUnknownRetry: PropTypes.func.isRequired,
    onLoadFilteredGlobalOptOutsRetry: PropTypes.func.isRequired,
    onLoadFilteredConsentersExpiringIn365DaysRetry: PropTypes.func.isRequired,
    onLoadFilteredConsentersExpiringIn180DaysRetry: PropTypes.func.isRequired,
    onLoadFilteredConsentersExpiringIn60DaysRetry: PropTypes.func.isRequired,
    onLoadFilteredExpiredConsentersRetry: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    isSuperuser: PropTypes.bool.isRequired,
    selectedTile: PropTypes.string.isRequired,
    switchSelectedTile: PropTypes.func.isRequired,
};

function mapStateToProps({
    aggregateStats,
    totalConsenters,
    filters,
    auth,
    errorFile,
}) {
    const { ...filterSelections } = filters.selections;

    return {
        transactionTotals: aggregateStats.transactionTotals,
        totalConsenters: totalConsenters.consenters,
        consenters: aggregateStats.consenters,
        filterSelections,
        metrics: aggregateStats.metrics,
        startDate: aggregateStats.startDate,
        endDate: aggregateStats.endDate,
        lastFilterSelections: aggregateStats.lastFilterSelections,
        dailyHistoryStats: aggregateStats.dailyHistoryStats,
        errorFileInfo: errorFile.errorFileInfo,
        historyIsLoading: loadingInProgress(
            aggregateStats.historyLoadingStatus
        ),
        aggregatesAreLoading: loadingInProgress(
            aggregateStats.aggregateLoadingStatus
        ),
        errorFileInfoIsLoading: loadingInProgress(
            errorFile.errorFileInfoStatus
        ),
        isSuperuser: auth.currentUser.user.is_superuser,
        selectedTile: aggregateStats.selectedTile,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            onLoadDailyHistoryStatsRetry: actions.loadDailyHistoryStats,
            onLoadFilteredConsentersGrantedRetry:
                actions.loadFilteredConsentersGranted,
            onLoadFilteredConsentersRevokedRetry:
                actions.loadFilteredConsentersRevoked,
            onLoadFilteredConsentersUnknownRetry:
                actions.loadFilteredConsentersUnknown,
            onLoadFilteredGlobalOptOutsRetry: actions.loadFilteredGlobalOptOuts,
            onLoadFilteredConsentersExpiringIn365DaysRetry:
                actions.loadFilteredConsentersExpiringIn365Days,
            onLoadFilteredConsentersExpiringIn180DaysRetry:
                actions.loadFilteredConsentersExpiringIn180Days,
            onLoadFilteredConsentersExpiringIn60DaysRetry:
                actions.loadFilteredConsentersExpiringIn60Days,
            onLoadFilteredExpiredConsentersRetry:
                actions.loadFilteredExpiredConsenters,
            onExport: exportActions.selectExportStatus,
            switchSelectedTile: actions.selectDashboardTile,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AggregateStatsContainer);
