/**
 * Actions related to exporting consenter preferences
 */

import * as types from '../constants/action-types';
import { createLoadingAction } from './action-helpers';
import { exportConsentersByFilter } from '../utils/backend';

/**
 * Action creator to export consent preferences via the backend
 * @param  {string} status          the consent preference status to export
 * @param  {object} filterOptions   options for filtering export
 *                                  (brand, program, etc)
 */
export const exportConsenters = createLoadingAction(
    types.SEND_EXPORT_STATUS,
    (filterOptions, status) => async dispatch => {
        await exportConsentersByFilter(filterOptions, status);
        dispatch({
            type: types.DESELECT_EXPORT_STATUS,
        });
    }
);

/**
 * Selects an active export status
 * @param  {string} status the consent preference status to set as active
 */
export const selectExportStatus = status => async dispatch => {
    dispatch({
        type: types.SET_SELECTED_EXPORT_STATUS,
        status: status,
    });
};

/**
 * Clears the active export status
 */
export const deselectExportStatus = () => async dispatch => {
    dispatch({
        type: types.DESELECT_EXPORT_STATUS,
    });
};
