// Consenter
export const CONSENTER_NOT_FOUND = 'CONSENTER_NOT_FOUND';
export const LOAD_CONSENTER = 'LOAD_CONSENTER';
export const LOAD_MATCHED_CONSENTERS = 'LOAD_MATCHED_CONSENTERS';
export const UPDATE_CONSENTER_LOAD_STATUS = 'UPDATE_CONSENTER_LOAD_STATUS';
export const UPDATE_CONSENTER_SEARCH_STATUS = 'UPDATE_CONSENTER_SEARCH_STATUS';
export const UPDATE_CONSENTER_ID = 'UPDATE_CONSENTER_ID';
export const UPDATE_CONSENTER_TYPE = 'UPDATE_CONSENTER_TYPE';
export const UNSET_CONSENTER = 'UNSET_CONSENTER';
export const UNSET_MATCHED_CONSENTERS = 'UNSET_MATCHED_CONSENTERS';

// Update Consent Preferences
export const UPDATE_CONSENT_PREFERENCES_REQUEST =
    'UPDATE_CONSENT_PREFERENCES_REQUEST';
export const UPDATE_CONSENT_PREFERENCES_CLEAR =
    'UPDATE_CONSENT_PREFERENCES_CLEAR';
export const UPDATE_CONSENT_PREFERENCES_CONSENT_STATUS_UPDATED =
    'UPDATE_CONSENT_PREFERENCES_CONSENT_STATUS_UPDATED';
export const UPDATE_CONSENT_PREFERENCES_EXPIRY_DATE_UPDATED =
    'UPDATE_CONSENT_PREFERENCES_EXPIRY_DATE_UPDATED';
export const UPDATE_CONSENT_PREFERENCES_SUBMIT_STATUS =
    'UPDATE_CONSENT_PREFERENCES_SUBMIT_STATUS';

// New Consent Preference
export const NEW_CONSENT_PREFERENCE_REQUEST = 'NEW_CONSENT_PREFERENCE_REQUEST';
export const NEW_CONSENT_PREFERENCE_CLEAR = 'NEW_CONSENT_PREFERENCE_CLEAR';
export const NEW_CONSENT_PREFERENCE_UPDATED = 'NEW_CONSENT_PREFERENCE_UPDATED';
export const NEW_CONSENT_PREFERENCE_SUBMIT_STATUS =
    'NEW_CONSENT_PREFERENCE_SUBMIT_STATUS';

// Auth
export const LOGIN_USER = 'LOGIN_USER';
export const UPDATE_LOGIN_STATUS = 'UPDATE_LOGIN_STATUS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UPDATE_LOGIN_CREDENTIALS = 'UPDATE_LOGIN_CREDENTIALS';

export const UPDATE_ACTIVATION_STATUS = 'UPDATE_ACTIVATION_STATUS';
export const UPDATE_CHECK_ACTIVATION_STATUS = 'UPDATE_CHECK_ACTIVATION_STATUS';
export const UPDATE_ACTIVATION_CREDENTIALS = 'UPDATE_ACTIVATION_CREDENTIALS';
export const SET_ACTIVATION_KEY = 'SET_ACTIVATION_KEY';
export const SET_HAS_WRITE_PERMISSION = 'SET_HAS_WRITE_PERMISSION';

// Initial Site Load
export const UPDATE_INITIAL_LOAD_STATUS = 'UPDATE_INITIAL_LOAD_STATUS';

// Aggregate Stats
export const LOAD_DAILY_HISTORY_STATS = 'LOAD_DAILY_HISTORY_STATS';
export const LOAD_DAILY_HISTORY_STATS_STATUS =
    'LOAD_DAILY_HISTORY_STATS_STATUS';

// Filters Options
export const LOAD_SYSTEMS = 'LOAD_SYSTEMS';
export const LOAD_BRANDS = 'LOAD_BRANDS';
export const LOAD_PROGRAMS = 'LOAD_PROGRAMS';
export const LOAD_CONSENT_TYPES = 'LOAD_CONSENT_TYPES';
export const LOAD_SERVICES = 'LOAD_SERVICES';
export const LOAD_CHANNELS = 'LOAD_CHANNELS';
export const LOAD_CONSENTER_TYPES = 'LOAD_CONSENTER_TYPES';
export const LOAD_CONSENTEE_NAMES = 'LOAD_CONSENTEE_NAMES';
export const SET_CANDIDATE_IDS = 'SET_CANDIDATE_IDS';
export const SELECT_FILTER_OPTION = 'SELECT_FILTER_OPTION';
export const FILTER_OPTIONS_LOAD_STATUS = 'FILTER_OPTIONS_LOAD_STATUS';

// Consenter Filtered Counts
export const LOAD_FILTERED_CONSENTERS_TITLE = 'LOAD_FILTERED_CONSENTERS_TITLE';
export const LOAD_FILTERED_CONSENTERS_TITLE_STATUS =
    'LOAD_FILTERED_CONSENTERS_TITLE_STATUS';
export const LOAD_FILTERED_CONSENTERS_GRANTED =
    'LOAD_FILTERED_CONSENTERS_GRANTED';
export const LOAD_FILTERED_CONSENTERS_GRANTED_STATUS =
    'LOAD_FILTERED_CONSENTERS_GRANTED_STATUS';
export const LOAD_FILTERED_CONSENTERS_REVOKED =
    'LOAD_FILTERED_CONSENTERS_REVOKED';
export const LOAD_FILTERED_CONSENTERS_REVOKED_STATUS =
    'LOAD_FILTERED_CONSENTERS_REVOKED_STATUS';
export const LOAD_FILTERED_CONSENTERS_UNKNOWN =
    'LOAD_FILTERED_CONSENTERS_UNKNOWN';
export const LOAD_FILTERED_CONSENTERS_UNKNOWN_STATUS =
    'LOAD_FILTERED_CONSENTERS_UNKNOWN_STATUS';
export const LOAD_FILTERED_GLOBAL_OPT_OUTS = 'LOAD_FILTERED_GLOBAL_OPT_OUTS';
export const LOAD_FILTERED_GLOBAL_OPT_OUTS_STATUS =
    'LOAD_FILTERED_GLOBAL_OPT_OUTS_STATUS';

// Consenter Expiration Counts
export const LOAD_FILTERED_CONSENTERS_EXPIRING_365_DAYS =
    'LOAD_FILTERED_CONSENTERS_EXPIRING_365_DAYS';
export const LOAD_FILTERED_CONSENTERS_EXPIRING_365_DAYS_STATUS =
    'LOAD_FILTERED_CONSENTERS_EXPIRING_365_DAYS_STATUS';
export const LOAD_FILTERED_CONSENTERS_EXPIRING_180_DAYS =
    'LOAD_FILTERED_CONSENTERS_EXPIRING_180_DAYS';
export const LOAD_FILTERED_CONSENTERS_EXPIRING_180_DAYS_STATUS =
    'LOAD_FILTERED_CONSENTERS_EXPIRING_180_DAYS_STATUS';
export const LOAD_FILTERED_CONSENTERS_EXPIRING_60_DAYS =
    'LOAD_FILTERED_CONSENTERS_EXPIRING_60_DAYS';
export const LOAD_FILTERED_CONSENTERS_EXPIRING_60_DAYS_STATUS =
    'LOAD_FILTERED_CONSENTERS_EXPIRING_60_DAYS_STATUS';
export const LOAD_FILTERED_CONSENTERS_EXPIRED =
    'LOAD_FILTERED_CONSENTERS_EXPIRED';
export const LOAD_FILTERED_CONSENTERS_EXPIRED_STATUS =
    'LOAD_FILTERED_CONSENTERS_EXPIRED_STATUS';

// Consenter Total Counts
export const CACHE_FILTER_VALUES = 'CACHE_FILTER_VALUES';
export const CACHE_FILTER_VALUES_STATUS = 'CACHE_FILTER_VALUES_STATUS';
export const LOAD_TOTAL_CONSENTERS_GRANTED = 'LOAD_TOTAL_CONSENTERS_GRANTED';
export const LOAD_TOTAL_CONSENTERS_GRANTED_STATUS =
    'LOAD_TOTAL_CONSENTERS_GRANTED_STATUS';
export const LOAD_TOTAL_CONSENTERS_REVOKED = 'LOAD_TOTAL_CONSENTERS_REVOKED';
export const LOAD_TOTAL_CONSENTERS_REVOKED_STATUS =
    'LOAD_TOTAL_CONSENTERS_REVOKED_STATUS';
export const LOAD_TOTAL_CONSENTERS_UNKNOWN = 'LOAD_TOTAL_CONSENTERS_UNKNOWN';
export const LOAD_TOTAL_CONSENTERS_UNKNOWN_STATUS =
    'LOAD_TOTAL_CONSENTERS_UNKNOWN_STATUS';
export const LOAD_TOTAL_GLOBAL_OPT_OUTS = 'LOAD_TOTAL_GLOBAL_OPT_OUTS';
export const LOAD_TOTAL_GLOBAL_OPT_OUTS_STATUS =
    'LOAD_TOTAL_GLOBAL_OPT_OUTS_STATUS';

// Exports
export const SEND_EXPORT_STATUS = 'SEND_EXPORT_STATUS';
export const SET_SELECTED_EXPORT_STATUS = 'SELECT_EXPORT_STATUS';
export const DESELECT_EXPORT_STATUS = 'DESELECT_EXPORT_STATUS';

// Filtered Consenters
export const LOAD_FILTERED_CONSENTERS = 'LOAD_FILTERED_CONSENTERS';
export const LOAD_FILTERED_CONSENTERS_STATUS =
    'LOAD_FILTERED_CONSENTERS_STATUS';

// Error File Info
export const LOAD_ERROR_FILE_INFO = 'LOAD_ERROR_FILE_INFO';
export const LOAD_ERROR_FILE_INFO_STATUS = 'LOAD_ERROR_FILE_INFO_STATUS';

// Toggle Dashboard Views
export const SELECT_DASHBOARD_TILE = 'SELECT_DASHBOARD_TILE';
