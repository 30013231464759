export const globalOptOutFields = {
    brand: 'Brand',
    program_name: 'Program',
    consent_type: 'Consent type',
    channel: 'Channel',
};

// A mapping of plural GOO field names to their singular labels; e.g. { brands: 'Brand', ... }
export const globalOptOutFieldsPluralToSingular = Object.entries(
    globalOptOutFields
).reduce((acc, [k, v]) => ({ ...acc, [k + 's']: v }), {});
