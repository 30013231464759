import * as types from '../constants/action-types';
const initialState = {
    // The loading status of the site
    status: null,
};

export default function initialLoadReducer(state = initialState, action) {
    switch (action.type) {
        case types.UPDATE_INITIAL_LOAD_STATUS:
            return Object.assign({}, state, { status: action.status });
        default:
            return state;
    }
}
