import React from 'react';

import LoginContainer from './components/LoginForm';
import CopyRight from '../Copyright';

export default function LoginPage() {
    return (
        <div className="registration-page">
            <LoginContainer />
            <CopyRight />
        </div>
    );
}
