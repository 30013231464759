import * as types from '../constants/action-types';
import * as loadingStatus from '../constants/loading-status';
import {
    consenterWithNewlyTransactedPreferences,
    submitConsentPreferences,
} from '../utils/backend';
import { getPreferenceCompositeKey } from '../utils/view-helpers';

export const requestUpdateConsentPreferences = () => ({
    type: types.UPDATE_CONSENT_PREFERENCES_REQUEST,
});

export const clearUpdatedConsentPreferences = () => ({
    type: types.UPDATE_CONSENT_PREFERENCES_CLEAR,
});

export const updateConsentPreferenceConsentStatus = (
    preferenceCompositeKey,
    consentStatus
) => ({
    type: types.UPDATE_CONSENT_PREFERENCES_CONSENT_STATUS_UPDATED,
    preferenceCompositeKey,
    consentStatus,
});

export const updateConsentPreferenceExpiryDate = (
    preferenceCompositeKey,
    expiryDate
) => ({
    type: types.UPDATE_CONSENT_PREFERENCES_EXPIRY_DATE_UPDATED,
    preferenceCompositeKey,
    expiryDate,
});

export const submitUpdatedConsentPreferences = (
    consenter,
    currentUserEmail,
    updatedPreferences
) => async dispatch => {
    dispatch({
        type: types.UPDATE_CONSENT_PREFERENCES_SUBMIT_STATUS,
        status: loadingStatus.STARTED,
    });
    try {
        const hydratedPreferences = hydrateUpdatedPreferences(
            updatedPreferences,
            consenter.preferences
        );
        const transactions = await submitConsentPreferences(
            consenter,
            currentUserEmail,
            hydratedPreferences
        );
        const freshConsenter = await consenterWithNewlyTransactedPreferences(
            transactions,
            consenter.consenter_id,
            consenter.consenter_type
        );
        dispatch({
            type: types.LOAD_CONSENTER,
            consenter: freshConsenter,
        });
        dispatch({
            type: types.UPDATE_CONSENT_PREFERENCES_SUBMIT_STATUS,
            status: loadingStatus.SUCCEEDED,
        });
        dispatch({ type: types.UPDATE_CONSENT_PREFERENCES_CLEAR });
    } catch (error) {
        dispatch({
            type: types.UPDATE_CONSENT_PREFERENCES_SUBMIT_STATUS,
            status: loadingStatus.FAILED,
        });
    }
};

/**
 * In order to keep reducer state usage as efficient as possible, we don't actually store the entire updated consent
 * preference in state, but rather only the fields that changed, with their new values: consentStatus,
 * and optionally also expiryDate. In order to help keep track of which changed values correspond to which original
 * consent preference, the changes are stored under the original preference's composite key.
 *
 * But an updated consentStatus and/or expiryDate isn't/aren't enough information information to send up to the server.
 * That's why this function also takes in the array of original consent preferences. The function iterates over each
 * original preference, keeping only the ones for which its composite key also exists in the preferenceUpdatesToHydrate
 * map. For those remaining preferences (which should be equivalent to every preference where a value was updated),
 * project only those keys that could represent a new or updated consent preference, temporarily re-mapping them from
 * snake_case to camelCase, and replacing the original values with the updated values for consentStatus and expiryDate,
 * whichever of the two exist.
 *
 * Think of powdered iced tea. It's stored efficiently in its container, and it can last a long time in your cupboard.
 * But you wouldn't want to drink it until you've added some water to it first, i.e. you've hydrated it.
 *
 * @param preferenceUpdatesToHydrate
 * @param originalPreferences
 * @returns {*}
 */
function hydrateUpdatedPreferences(
    preferenceUpdatesToHydrate,
    originalPreferences
) {
    return originalPreferences
        .map(p => [p, preferenceUpdatesToHydrate[getPreferenceCompositeKey(p)]])
        .filter(pair => pair[1])
        .map(([original, updated]) => ({
            brand: original.brand,
            program: original.program_name,
            consentType: original.consent_type,
            channel: original.channel,
            consentStatus: updated.consentStatus || original.status,
            expiryDate: updated.expiryDate || original.expiry_date,
            serviceName: updated.serviceName || original.service_name,
        }));
}
