import * as types from '../constants/action-types';
import * as loadingStatus from '../constants/loading-status';
import {
    loginUser as loginUserBackend,
    logoutUser as logoutUserBackend,
    activateUser as activateUserBackend,
    checkActivationKey as checkActivationKeyBackend,
    checkHasWritePermission as checkHasWritePermissionBackend,
} from '../utils/backend';
import { createLoadingAction } from './action-helpers';

export const loginUser = ({ username, password }) => async dispatch => {
    dispatch({
        type: types.UPDATE_LOGIN_STATUS,
        status: loadingStatus.STARTED,
    });
    try {
        dispatch({
            type: types.LOGIN_USER,
            user: await loginUserBackend({ username, password }),
        });
        dispatch({
            type: types.UPDATE_LOGIN_STATUS,
            status: loadingStatus.SUCCEEDED,
        });
    } catch (error) {
        dispatch({
            type: types.UPDATE_LOGIN_STATUS,
            status: loadingStatus.FAILED,
        });
    }
};

export const logoutUser = () => dispatch => {
    logoutUserBackend();
    dispatch({
        type: types.LOGOUT_USER,
    });
};

export const updateLoginCredentials = ({ username, password }) => ({
    type: types.UPDATE_LOGIN_CREDENTIALS,
    username,
    password,
});

/**
 * An action creator that updates activation credentials, based on user input
 * @param  {string} options.password
 * @param  {string} options.confirmation
 */
export const updateActivationCredentials = ({ password, confirmation }) => ({
    type: types.UPDATE_ACTIVATION_CREDENTIALS,
    password,
    confirmation,
});

/**
 * An action creator activates the user based on the provided credentials
 * and dispatches relevant actions
 * @param  {string} options.password
 * @param  {string} options.activationKey
 */
export const activateUser = createLoadingAction(
    types.UPDATE_ACTIVATION_STATUS,
    ({ password, activationKey }) => async dispatch => {
        try {
            const user = await activateUserBackend({ password, activationKey });
            // Login user once activated
            dispatch({
                type: types.LOGIN_USER,
                user: user,
            });
        } catch (error) {
            if (error && error.response && error.response === 400) {
                return;
            }
            throw error;
        }
    }
);

/**
 * An action creator checks if an activation key is valid
 * and dispatches relevant actions
 * @param  {string} activationKey
 */
export const checkActivationKey = createLoadingAction(
    types.UPDATE_CHECK_ACTIVATION_STATUS,
    activationKey => async dispatch => {
        await checkActivationKeyBackend(activationKey);
        dispatch({
            type: types.SET_ACTIVATION_KEY,
            activationKey: activationKey,
        });
    }
);

/**
 * An action creator checks if the user has write access
 * and dispatches relevant actions
 */
export const checkHasWritePermission = () => async dispatch => {
    dispatch({
        type: types.SET_HAS_WRITE_PERMISSION,
        hasWritePermission: await checkHasWritePermissionBackend(),
    });
};
