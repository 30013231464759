import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../../../actions/auth-actions';
import { Redirect } from 'react-router-dom';
import LoginForm from './LoginForm';
import * as loadingStatus from '../../../../../constants/loading-status';
import { Alert } from 'reactstrap';

/**
 * LoginContainer component
 * @param {Function} options.onCredentialChange Event handler when username/password credentials change
 * @param {Function} options.onLoginSubmit      Event handler when credentials are submitted for login
 * @param {string} options.username           The current value of username
 * @param {string} options.password           The current value of password
 * @param {boolean} options.isLoggedIn         Whether a user is logged in
 * @param {string} options.loginStatus        The load status of logging in
 */
function LoginContainer({
    onCredentialChange,
    onLoginSubmit,
    username,
    password,
    isLoggedIn,
    loginStatus,
}) {
    if (isLoggedIn) {
        return <Redirect to="/" />;
    }
    let alert;
    if (loginStatus == loadingStatus.FAILED) {
        alert = (
            <Alert color="warning">
                The username and/or password is inaccurate.
            </Alert>
        );
    }
    return (
        <div className="registration-container">
            <div className="header">
                <span className="card-title">Welcome</span>
            </div>
            <hr />
            {alert}
            <LoginForm
                onChange={onCredentialChange}
                onSubmit={onLoginSubmit}
                username={username}
                password={password}
                disabled={loginStatus === loadingStatus.STARTED}
            />
        </div>
    );
}

LoginContainer.propTypes = {
    onLoginSubmit: PropTypes.func.isRequired,
    onCredentialChange: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    loginStatus: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        username: state.auth.credentials.username,
        password: state.auth.credentials.password,
        loginStatus: state.auth.loginStatus,
        isLoggedIn: Boolean(state.auth.currentUser),
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            onLoginSubmit: actions.loginUser,
            onCredentialChange: actions.updateLoginCredentials,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginContainer);
