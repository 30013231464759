import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { globalOptOutFieldsPluralToSingular } from '../../../../../constants/labels';

import ConsenterHistoryTable from './ConsenterHistoryTable';
import {
    formatConsentTypeText,
    isGlobalOptOut,
    prettifyConsenterTypeText,
} from '../../../../../utils/view-helpers';

const generateConsentPreferenceEvent = ({
    brand,
    consent_type,
    program_name,
    service_name,
    channel,
    status,
}) => {
    const fields = [brand, consent_type, program_name, service_name, channel]
        .filter(Boolean)
        .map(formatConsentTypeText)
        .join(', ');

    return `Consent ${status} (${fields})`;
};

const generateConsentPreferenceExpiredEvent = ({
    brand,
    consent_type,
    program_name,
    service_name,
    channel,
}) => {
    const fields = [brand, consent_type, program_name, service_name, channel]
        .filter(Boolean)
        .map(formatConsentTypeText)
        .join(', ');

    return `Consent expired (${fields})`;
};

const generateGlobalOptOutEvent = (preference, affectedPreferences) => {
    // Synthesize an event that explicitly lists what was opted out,
    // for each potentially globally “Opt-Out”-able field
    const optedOut = Object.entries(globalOptOutFieldsPluralToSingular)
        .map(([k, v]) => {
            const optOutList = preference[k]
                ? preference[k].map(formatConsentTypeText).join(', ')
                : 'All';
            return `${v}: ${optOutList}`;
        })
        .join('; ');
    const affectedPreferenceEvents = affectedPreferences.map((pref, i) => (
        <div key={i}>{generateConsentPreferenceEvent(pref)}</div>
    ));
    return (
        <div>
            <div>
                <b>Global opt-out ({optedOut})</b>
            </div>
            {affectedPreferenceEvents}
        </div>
    );
};

const linkToPatient = (consenterId, consenterType) => {
    return (
        <Link to={`/consenter/${consenterType}/${consenterId}`}>
            {consenterId}
        </Link>
    );
};

const formatUnmergeHistory = (
    selectedMergeIds,
    canonicalId,
    contributorId,
    consenterType
) => {
    let canonicalIdText = canonicalId;
    if (!selectedMergeIds.includes(canonicalId)) {
        canonicalIdText = linkToPatient(canonicalId, consenterType);
    }
    let contributorIdText = contributorId;
    if (!selectedMergeIds.includes(contributorId)) {
        contributorIdText = linkToPatient(contributorId, consenterType);
    }

    return [
        <span key={canonicalId}>
            {prettifyConsenterTypeText(consenterType)} unmerged from{' '}
            {canonicalIdText}
        </span>,
        contributorIdText,
    ];
};

const generateConsenterHistoryRecord = selectedMergeIds => {
    return record => {
        const { event_time, type, data } = record;
        const {
            affected_preferences,
            preference,
            expired_preference,
            consenter_id,
            consenter_type,
            src_id,
            dst_id,
            contributor_id,
            canonical_id,
        } = data;

        let transactionDate;
        let event;
        let sourceSystem = 'n/a';
        let consenterId = consenter_id;
        switch (type) {
            case 'preference_added_history':
                transactionDate = preference['transaction_date'];
                event = generateConsentPreferenceEvent(preference);
                sourceSystem = formatConsentTypeText(preference['system'].name);
                break;
            case 'merge_history':
                transactionDate = event_time;
                event = `${prettifyConsenterTypeText(
                    consenter_type
                )} merged, formerly ${src_id}`;
                consenterId = dst_id;
                break;
            case 'unmerge_history':
                [event, consenterId] = formatUnmergeHistory(
                    selectedMergeIds,
                    canonical_id,
                    contributor_id,
                    consenter_type
                );
                transactionDate = event_time;
                break;
            case 'multi_value_preference_added_history':
                if (!isGlobalOptOut(preference)) {
                    return;
                }
                transactionDate = preference['transaction_date'];
                event = generateGlobalOptOutEvent(
                    preference,
                    affected_preferences
                );
                sourceSystem = formatConsentTypeText(preference['system'].name);
                break;
            case 'expired_preference_history':
                transactionDate = event_time;
                event = generateConsentPreferenceExpiredEvent(
                    expired_preference
                );
                sourceSystem = formatConsentTypeText(
                    expired_preference['system'].name
                );
                break;
            default:
                // Return undefined if there the history type is not recognized
                return;
        }

        return {
            transaction_date: transactionDate,
            event: event,
            event_time: event_time,
            source_system: sourceSystem,
            consenter_id: consenterId,
            event_type: type,
        };
    };
};

/**
 * Compares two consenter history record objects. Used to sort consenter history
 * records in descending order by transaction_date
 * @param {object} r1 - Consenter history record object
 * @param {object} r2 - Consenter history record object to compare against
 */
const compareConsenterHistoryRecords = (r1, r2) => {
    const date1 = moment(r1.transaction_date);
    if (date1.isBefore(r2.transaction_date)) {
        return 1;
    } else if (date1.isAfter(r2.transaction_date)) {
        return -1;
    }
    return 0;
};

function ConsenterHistoryTableContainer({ consenter }) {
    const consenterMergeIds = consenter.merge_ids || [consenter.consenter_id];

    const consenterHistoryRecords = consenter
        ? consenter.history
              .map(generateConsenterHistoryRecord(consenterMergeIds))
              .filter(history => history)
              .sort(compareConsenterHistoryRecords)
        : [];

    return (
        <ConsenterHistoryTable
            consenterHistoryRecords={consenterHistoryRecords}
        />
    );
}

ConsenterHistoryTableContainer.propTypes = {
    consenter: PropTypes.object,
};

const mapStateToProps = ({ consenter }) => ({
    consenter: consenter.consenter,
});

export default connect(mapStateToProps)(ConsenterHistoryTableContainer);
