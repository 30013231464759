// Set up your application entry point here...

import ReactGA from 'react-ga';

const USERNAME_FIELD = 'dimension1';

const googleAnalyticsActivated = () => {
    // Google Analytics should only work on "production"
    return process.env.ANALYTICS_ACTIVATED === 'true';
};

export const registerUser = user => {
    if (googleAnalyticsActivated()) {
        ReactGA.set({
            [USERNAME_FIELD]: user.username,
        });
    }
};

export const unregisterUser = () => {
    if (googleAnalyticsActivated()) {
        ReactGA.set({
            [USERNAME_FIELD]: null,
        });
    }
};

export const googleAnalyticsEvent = args => {
    if (googleAnalyticsActivated()) {
        ReactGA.event(args);
    }
};

export const initializeGoogleAnalytics = history => {
    if (googleAnalyticsActivated()) {
        ReactGA.initialize(process.env.ANALYTICS_TRACKING_CODE);
        history.listen(location => ReactGA.pageview(location.pathname));
    }
};
