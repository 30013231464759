import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as newConsentPreferenceActions from '../../../../../../../actions/new-consent-preference-actions';
import AddNewConsentPreferenceModal from './AddNewConsentPreferenceModal';

class AddNewConsentPreferenceModalContainer extends React.Component {
    componentDidMount() {
        this.syncNewConsentPreferenceRequestToLocation();
    }

    componentDidUpdate(prevProps) {
        if (!this.props.hasWritePermission) {
            this.props.history.push(this.props.match.url);
        }

        const { newConsentPreferenceRequested: prevRequested } = prevProps;
        const { newConsentPreferenceRequested: currRequested } = this.props;

        if (!prevRequested && currRequested) {
            this.props.history.push(
                `${this.props.match.url}/add-new-consent-preference`
            );
        } else if (prevRequested && !currRequested) {
            this.props.history.push(this.props.match.url);
        }
    }

    syncNewConsentPreferenceRequestToLocation() {
        if (
            this.props.location.pathname.endsWith('add-new-consent-preference')
        ) {
            this.props.requestNewConsentPreference();
        } else {
            this.props.clearNewConsentPreference();
        }
    }

    render() {
        return (
            this.props.hasWritePermission && (
                <AddNewConsentPreferenceModal {...this.props} />
            )
        );
    }
}

AddNewConsentPreferenceModalContainer.propTypes = {
    consenter: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    newConsentPreferenceRequested: PropTypes.bool.isRequired,
    requestNewConsentPreference: PropTypes.func.isRequired,
    clearNewConsentPreference: PropTypes.func.isRequired,
    currentUserEmail: PropTypes.string.isRequired,
    knownBrandsAndProgramsAndServiceNames: PropTypes.shape({
        brands: PropTypes.arrayOf(PropTypes.string).isRequired,
        programs: PropTypes.arrayOf(PropTypes.string).isRequired,
        serviceNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    newConsentPreferenceConfig: PropTypes.object.isRequired,
    newConsentPreference: PropTypes.object.isRequired,
    updateNewConsentPreference: PropTypes.func.isRequired,
    submitNewConsentPreference: PropTypes.func.isRequired,
    newConsentPreferenceSubmitting: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
    const newConfig = {
        ...state.newConsentPreference.config,
        channels: state.filters.options.channels,
        consentTypes: state.filters.options.consent_types,
    };

    return {
        currentUserEmail: state.auth.currentUser.user.email,
        knownBrandsAndProgramsAndServiceNames: {
            brands: state.filters.options.brands,
            programs: state.filters.options.program_names,
            serviceNames: state.filters.options.service_names,
        },
        newConsentPreferenceConfig: newConfig,
        newConsentPreference: state.newConsentPreference.preference,
        newConsentPreferenceSubmitting: state.newConsentPreference.submitting,
        hasWritePermission: state.auth.hasWritePermission,
    };
}

function mapDispatchToProps(dispatch) {
    const actionCreators = {
        requestNewConsentPreference:
            newConsentPreferenceActions.requestNewConsentPreference,
        clearNewConsentPreference:
            newConsentPreferenceActions.clearNewConsentPreference,
        updateNewConsentPreference:
            newConsentPreferenceActions.updateNewConsentPreference,
        submitNewConsentPreference:
            newConsentPreferenceActions.submitNewConsentPreference,
    };
    return bindActionCreators(actionCreators, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddNewConsentPreferenceModalContainer);
