/**
 * Container for the export modal
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadingUnsuccessful } from '../../../shared/selectors';
import ExportModal from './ExportModal';
import ErrorBar from '../../../shared/ErrorBar';
import { bindActionCreators } from 'redux';
import {
    deselectExportStatus,
    exportConsenters,
} from '../../../../../actions/export-actions';

function ExportModalContainer({
    exportStatus,
    exportFailed,
    onCancel,
    filterOptions,
    email,
    onExport,
}) {
    if (!exportStatus) {
        return null;
    }
    if (exportFailed) {
        var error =
            'Something went wrong. Please try again or contact system administrator.';
        return <ErrorBar errorMessage={error} />;
    }
    return (
        <ExportModal
            onCancel={onCancel}
            onExport={() => onExport(filterOptions, exportStatus)}
            email={email}
        />
    );
}

ExportModalContainer.propTypes = {
    exportStatus: PropTypes.string,
    exportFailed: PropTypes.bool.isRequired,
    filterOptions: PropTypes.object.isRequired,
    email: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            onExport: exportConsenters,
            onCancel: deselectExportStatus,
        },
        dispatch
    );
}

function mapStateToProps(state) {
    const { sendExportStatus, selectedExportStatus } = state.exports;
    const { start_date, end_date, ...filterOptions } = state.filters.selections;
    const { email } = state.auth.currentUser.user;

    return {
        email,
        filterOptions,
        exportFailed: loadingUnsuccessful(sendExportStatus),
        exportStatus: selectedExportStatus,
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExportModalContainer);
