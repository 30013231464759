import * as types from '../constants/action-types';
import { equalsIgnoreCase } from '../utils/view-helpers';

// Initial Start Date is 60 days ago
// Initial End Date is today
export const initialStartDate = new Date();
export const initialEndDate = new Date();
initialStartDate.setDate(initialEndDate.getDate() - 60);

const initialState = {
    options: {
        source_system_names: [],
        brands: [],
        program_names: [],
        consent_types: [],
        service_names: [],
        channels: [],
        consenter_types: [],
        consentee_names: [],
    },
    selections: {
        source_system_name: '',
        brand: '',
        program_name: '',
        consent_type: '',
        service_name: '',
        channel: '',
        consenter_type: '',
        consentee_name: '',
        start_date: initialStartDate,
        end_date: initialEndDate,
    },
    candidate_ids: '',
};

function updateOptions(state, key, value) {
    return Object.assign({}, state, {
        options: Object.assign({}, state.options, { [key]: value }),
    });
}

export default function filterReducer(state = initialState, action) {
    switch (action.type) {
        case types.LOAD_SYSTEMS:
            return updateOptions(
                state,
                'source_system_names',
                action.source_system_names
            );
        case types.LOAD_BRANDS:
            return updateOptions(state, 'brands', action.brands);
        case types.LOAD_PROGRAMS:
            return updateOptions(state, 'program_names', action.program_names);
        case types.LOAD_CONSENT_TYPES:
            return updateOptions(state, 'consent_types', action.consent_types);
        case types.LOAD_SERVICES:
            return updateOptions(state, 'service_names', action.service_names);
        case types.LOAD_CHANNELS:
            return updateOptions(state, 'channels', action.channels);
        case types.LOAD_CONSENTER_TYPES:
            return updateOptions(
                state,
                'consenter_types',
                action.consenter_types
            );
        case types.LOAD_CONSENTEE_NAMES:
            return updateOptions(
                state,
                'consentee_names',
                action.consentee_names
            );
        case types.SET_CANDIDATE_IDS:
            return {
                ...state,
                candidate_ids: action.candidate_ids,
            };
        case types.SELECT_FILTER_OPTION: {
            const update = {
                [action.fieldName]: action.fieldVal,
            };
            if (
                action.fieldName === 'consent_type' &&
                !equalsIgnoreCase(action.fieldVal, 'Service')
            ) {
                update['service_name'] = '';
            }
            return Object.assign({}, state, {
                selections: Object.assign({}, state.selections, update),
            });
        }
        default:
            return state;
    }
}
