/**
 * This modal largely acts as a confirmation prompt for a user to export
 * consent preferences on the backend
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Modal, ModalBody, ModalHeader } from 'reactstrap';

import '../../../../../styles/export-modal.scss';

export default function ExportModal({ email, onExport, onCancel }) {
    return (
        <Modal className="export-popup" size="sm" isOpen={true}>
            <ModalHeader>Export data</ModalHeader>
            <ModalBody>
                <Alert color="success">
                    You are about to export consenters. The data you have
                    selected will be exported&nbsp;
                    <span className="bold-text">
                        and you will receive a download link via email&nbsp;
                    </span>
                    ({email}).
                </Alert>
                <button className="okay" onClick={onExport}>
                    Export
                </button>

                <button className="cancel" onClick={onCancel}>
                    Cancel
                </button>
            </ModalBody>
        </Modal>
    );
}

ExportModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
};
