import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import HistoryDateSelection from './HistoryDateSelection';
import { anyInProgress } from '../../../../../shared/selectors';
import { loadAllStats } from '../../../../../../../actions/aggregate-stats-actions';
import { selectFilterOption } from '../../../../../../../actions/filter-actions';

function HistoryDateSelectionContainer(props) {
    /* Container for the HistoryDateSelection Component */

    return (
        <HistoryDateSelection
            onChange={props.onFilterChange}
            onSubmit={props.onFilterSubmit}
            isSubmitting={props.isSubmitting}
            selections={props.selections}
        />
    );
}

HistoryDateSelectionContainer.propTypes = {
    onFilterChange: PropTypes.func.isRequired,
    onFilterSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    selections: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        selections: state.filters.selections,
        isSubmitting: anyInProgress(
            state.aggregateStats.aggregateLoadingStatus,
            state.aggregateStats.historyLoadingStatus
        ),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            onFilterChange: selectFilterOption,
            onFilterSubmit: loadAllStats,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HistoryDateSelectionContainer);
