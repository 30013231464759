/**
 * A generic error component for displaying error messages to the user
 */

import React from 'react';
import PropTypes from 'prop-types';

import '../../../styles/error-bar.scss';

export default function ErrorBar(props) {
    return <div className="error-bar">{props.errorMessage}</div>;
}

ErrorBar.propTypes = {
    errorMessage: PropTypes.string.isRequired,
};
