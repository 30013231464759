import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as loadingStatus from '../../../../../constants/loading-status';
import { Alert } from 'reactstrap';

/**
 * InitialLoadContainer component
 * @param {string} options.status        The load status of logging in
 */
function InitialLoadContainer({ status, children }) {
    switch (status) {
        case loadingStatus.FAILED:
            return (
                <Alert color="warning">
                    The site failed to load. Please contact customer support.
                </Alert>
            );
        case loadingStatus.SUCCEEDED:
            return children;
        default:
            return <Alert color="secondary">Loading...</Alert>;
    }
}

InitialLoadContainer.propTypes = {
    children: PropTypes.object,
    status: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        status: state.initialLoad.status,
    };
}

function mapDispatchToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitialLoadContainer);
