/**
 * A generic loading component used to present a loading spinner when
 * something is processing.
 *
 * When `isLoading` is true, the spinner will be displayed.
 *
 * When `isLoading` is false, the `component` will be rendered with the
 * all of the rest of the props passed into the `LoadingSpinner`
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Loading Spinner Component
 * @param {boolean}    options.isLoading      Whether this component is loading
 * @param {object}     options.component      The component type to render when `isLoading` is false
 * @param {...object}  options.componentProps The props to pass to `component`
 * @example
 *     function MyComponent ({prop1, prop2}) {
 *         return <span>{prop1} {prop2}</span>;
 *     }
 *
 *     function MyPage ({data}) {
 *         <LoadingSpinner
 *             isLoading={!data}
 *             component={MyComponent}
 *             prop1={"Hello"}
 *             prop2={"world"}
 *         />
 *     }
 */
export default function LoadingSpinner({
    isLoading,
    component,
    ...componentProps
}) {
    // variable name needs to be capitalized to be recognized by React
    const Component = component;
    if (!isLoading) {
        return <Component {...componentProps} />;
    }
    return (
        <div className="loading-spinner">
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            <span className="spinner-text">May take a minute</span>
        </div>
    );
}

LoadingSpinner.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    component: PropTypes.elementType.isRequired,
    componentProps: PropTypes.object,
};
