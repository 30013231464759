import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';

/**
 * LoginForm component
 * @param {string} options.username The username value of the user
 * @param {string} options.password The password value of the user
 * @param {Function} options.onSubmit Event handler for the form submission
 * @param {Function} options.onChange Event handler for username/password updates
 * @param {Function} options.disable Whether to disable submit
 */
export default function LoginForm({
    username,
    password,
    onSubmit,
    onChange,
    disabled,
}) {
    /**
     * Whenever a field changes, call the onChange callback with the current
     * credentials, updated with the latest value
     */
    const onFieldChange = fieldName => evt =>
        onChange(
            Object.assign(
                { username, password },
                { [fieldName]: evt.target.value }
            )
        );

    /**
     * Call the onSubmit callback with username/password credentials,
     * only if the form is not disabled
     */
    const onFormSubmit = evt => {
        evt.preventDefault();
        if (!disabled) {
            onSubmit({ username, password });
        }
        return false;
    };

    return (
        <div className="registration-form-container">
            <Form className="registration-form" onSubmit={onFormSubmit}>
                <FormGroup>
                    <Label for="login-username">Username</Label>
                    <Input
                        type="text"
                        id="login-username"
                        onChange={onFieldChange('username')}
                        placeholder="Enter a username (email address)"
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="login-password">Password</Label>
                    <Input
                        type="password"
                        id="login-password"
                        onChange={onFieldChange('password')}
                        placeholder="Enter password"
                        autoComplete="off"
                    />
                </FormGroup>
                <Button
                    className="hv-btn-gr-green float-right"
                    disabled={disabled}
                >
                    Sign in
                </Button>
            </Form>
        </div>
    );
}

LoginForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
};
