import axios from 'axios';
import moment from 'moment';
import { registerUser, unregisterUser } from '../actions/analytics';

// To change this environment variable or its default, see the
// webpack.config.common.js file
export const BASE_URL = process.env.CONSENT_ADMIN_BACKEND_BASE_URL;
const AUTH_TOKEN_KEY = 'consent-authtoken';

export const request = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-type': 'application/json',
    },
});

if (localStorage.getItem(AUTH_TOKEN_KEY)) {
    setAuthToken(localStorage.getItem(AUTH_TOKEN_KEY));
}

export const loginUser = async ({ username, password }) => {
    unsetAuthToken();
    const resp = await request.post('auth/login/', { username, password });
    const token = resp.data.key;
    setAuthToken(token);
    return await loadCurrentUser();
};

/**
 * Load current user if exists
 * @return {object} current user data
 */
export const loadCurrentUser = async () => {
    try {
        const { data } = await request.get('user/');
        registerUser(data.user);
        return data;
    } catch (err) {
        if (
            err.response &&
            (err.response.status === 401 || err.response.status === 403)
        ) {
            return null;
        }
    }
};

export const logoutUser = async () => {
    try {
        unregisterUser();
        await request.post('auth/logout/');
    } finally {
        unsetAuthToken();
    }
};

/**
 * Activates user with the provided password and activation key, then returns
 * the current user
 * @async
 * @param  {options.string}         password
 * @param  {options.activationKey}  activationKey
 * @return {object} current user
 */
export const activateUser = async ({ password, activationKey }) => {
    const result = await request.post('activate/', {
        password,
        activation_key: activationKey,
    });
    const key = result.data.key;
    setAuthToken(key);
    return await loadCurrentUser();
};

/**
 * Checks if the activation key is valid
 * @async
 * @param  {string} activationKey
 * @return {boolean}
 */
export const checkActivationKey = async activationKey => {
    const query = formatQueryString({
        activation_key: activationKey,
    });
    await request.get(`activate/${query}`);
};

/**
 * Checks if the user has write access
 * @async
 * @return {boolean}
 */
export const checkHasWritePermission = async () => {
    const resp = await request.get(`has_write_permission/`);
    return resp.data.has_write_permission;
};

export const getConsenter = async (id, type, include_expired) => {
    try {
        const url = `${BASE_URL}/consenter/${formatQueryString({
            consenter_id: id,
            consenter_type: type,
            include_expired: include_expired,
        })}`;
        const resp = await request.get(url);
        return resp.data;
    } catch (err) {
        if (err.response && err.response.status === 404) {
            return null;
        }
        throw err;
    }
};

export const getConsenterTypes = async () => {
    const resp = await request.get('config/');
    return resp.data.consenter_types;
};

export const submitConsentPreferences = async (
    consenter,
    currentUserEmail,
    preferences
) => {
    const response = await request.post('preferences/import/', {
        preferences: translateConsenterPreferencesForBackend(
            preferences,
            currentUserEmail,
            consenter
        ),
    });
    return response.data;
};

/**
 * Transform the specified consent preferences into a format suitable for submitting to the backend.
 * - Add preset data to all of the consent preferences, including:
 * -- `Consent UI (${currentUserEmail})` as the system and its current user's email
 *    from which the consent preferences originated
 * -- Current datetime as the transaction date and last update time
 * -- region, consenter id, consenter type from the consenter
 * -- consentee name and type of the consenter's most recent consent preference.
 *    (FIXME: Shouldn't these come the current user or some other source?)
 * - Remap existing values from camelCase to snake_case key names
 *
 * @param preferences
 * @param currentUserEmail
 * @param consenter
 * @returns {*}
 */
function translateConsenterPreferencesForBackend(
    preferences,
    currentUserEmail,
    consenter
) {
    const { region, consenter_id, consenter_type } = consenter;
    const { consentee_name, consentee_type } = mostRecentPreferenceOf(
        consenter
    );
    const today = new Date();
    const presets = {
        system: `Consent UI (${currentUserEmail})`,
        transaction_date: today,
        last_update_time: today,
        region,
        consenter_id,
        consenter_type,
        consentee_name,
        consentee_type,
    };
    const backendFieldNames = {
        brand: 'brand',
        program: 'program_name',
        consentType: 'consent_type',
        channel: 'channel',
        consentStatus: 'status',
        expiryDate: 'expiry_date',
        serviceName: 'service_name',
    };

    return preferences.map(p =>
        Object.assign(
            {},
            presets,
            Object.entries(backendFieldNames).reduce(
                (acc, [frontEndName, backendName]) => ({
                    ...acc,
                    [backendName]: p[frontEndName] || '',
                }),
                {}
            )
        )
    );
}

function mostRecentPreferenceOf(consenter) {
    const prefs = Array.from(consenter.preferences);
    prefs.sort(({ transaction_date: t1 }, { transaction_date: t2 }) =>
        t2.localeCompare(t1)
    );
    return prefs.shift();
}

export const consenterWithNewlyTransactedPreferences = (
    { tx_id },
    consenterId,
    consenterType
) => {
    function ensureTransactionCommitted(consenter) {
        const transactionCommitted = consenter.preferences.some(
            ({ transaction_id }) => transaction_id === tx_id
        );

        if (transactionCommitted) {
            return consenter;
        } else {
            throw consenter;
        }
    }

    return poll(
        () =>
            getConsenter(consenterId, consenterType, true).then(
                ensureTransactionCommitted
            ),
        15,
        1000
    );
};

function poll(fn, retries = Infinity, timeoutBetweenAttempts = 1000) {
    return Promise.resolve()
        .then(fn)
        .catch(function retry(err) {
            if (retries-- > 0)
                return delay(timeoutBetweenAttempts)
                    .then(fn)
                    .catch(retry);
            throw err;
        });
}

function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function formatQueryString(obj, { filterEmpty = false } = {}) {
    let qs = '?';
    for (let key in obj) {
        let val = obj[key];
        // Skip keys where the value is not defined if filterEmpty is true
        if (filterEmpty && !val) {
            continue;
        }
        qs = `${qs}${encodeURIComponent(key)}=${encodeURIComponent(val)}&`;
    }
    return qs;
}

function setAuthToken(token) {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
    request.defaults.headers.common.Authorization = `Token ${token}`;
}

function unsetAuthToken() {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    delete request.defaults.headers.common.Authorization;
}

/*
 * Aggregate Stats Backend
 */
export const getFilteredConsenters = async filterOptions => {
    const query = formatQueryString(filterOptions, { filterEmpty: true });
    const { data } = await request.get(`total-consenters/${query}`);
    return data;
};

export const getFilteredGlobalOptOuts = async filterOptions => {
    const query = formatQueryString(filterOptions, { filterEmpty: true });
    const { data } = await request.get(`total-global-opt-outs/${query}`);
    return data;
};

export const getFilteredCandidates = async (candidateIds, filterOptions) => {
    if (!candidateIds.length) {
        return [];
    }
    const filterQueryParams = formatQueryString(filterOptions, {
        filterEmpty: true,
    });
    const resp = await request.post(
        `filtered_candidates/${filterQueryParams}`,
        { candidate_ids: candidateIds.split('\n') }
    );
    return resp.data;
};

export const getExpiringConsenters = async (days, filterOptions) => {
    const query = formatQueryString(filterOptions, { filterEmpty: true });
    const resp = await request.get(`consenters/expire/${days}/${query}`);
    // adds base url to the link
    resp.data.link = `${BASE_URL}${resp.data.link}`;
    return resp.data;
};

export const getFilterOptions = async () => {
    const { data } = await request.get('filter-options/');
    return {
        source_system_names: data.source_system_name,
        brands: data.brand,
        consent_types: data.consent_type,
        service_names: data.service_name,
        channels: data.channel,
        program_names: data.program_name,
        consenter_types: data.consenter_type,
        consentee_names: data.consentee_name,
    };
};

export const getDailyHistoryStats = async (
    start_date,
    end_date,
    filterOptions
) => {
    const query = formatQueryString(
        {
            ...filterOptions,
            start_date: formatDate(start_date),
            end_date: formatDate(end_date),
        },
        { filterEmpty: true }
    );
    const resp = await request.get(`daily-history-stats/${query}`);
    return resp.data;
};

/**
 * Kicks off an asynchronous consent preferences export on the backend
 * @param  {object} filters params to filter the export by
 * @param  {string} status  the status of the consent preferences to export
 */
export const exportConsentersByFilter = async (filters, status) => {
    const query = formatQueryString(filters, { filterEmpty: true });
    await request.get(`preferences/export/${status}/${query}`);
};

function formatDate(date) {
    return moment(date).format(moment.HTML5_FMT.DATE);
}

/**
 * Kicks off an asynchronous consent preferences upload on the backend from
 * a bytestream.  file_name tells how to label the resultant upload on S3.
 */
export const uploadBatchIngestFile = async (fileObj, ingest_type) => {
    const data = new FormData();
    data.append('file', fileObj, fileObj.name);
    data.append('ingest_type', ingest_type);
    return request.post('ingest_batchfile', data);
};

/**
 * Retrieves error file information from the admin backend.
 */
export const getErrorFileInfo = async (start_date, end_date) => {
    const query = formatQueryString(
        {
            start_date: formatDate(start_date),
            end_date: formatDate(end_date),
        },
        { filterEmpty: true }
    );
    const resp = await request.get(`error-file-info/${query}`);
    return resp.data.error_file_info;
};
