import { combineReducers } from 'redux';
import consenterReducer from './consenter-reducer';
import filterReducer from './filter-reducer';
import authReducer from './auth-reducer';
import initialLoadReducer from './initial-load-reducer';
import errorFileReducer from './error-file-reducer';
import exportReducer from './export-reducer';
import newConsentPreferenceReducer from './new-consent-preference-reducer';
import updateConsentPreferencesReducer from './update-consent-preferences-reducer';
import { aggregateStatsReducer, totalStatsReducer } from './stats-reducers';

const rootReducer = combineReducers({
    consenter: consenterReducer,
    newConsentPreference: newConsentPreferenceReducer,
    updateConsentPreferences: updateConsentPreferencesReducer,
    auth: authReducer,
    initialLoad: initialLoadReducer,
    aggregateStats: aggregateStatsReducer,
    totalConsenters: totalStatsReducer,
    errorFile: errorFileReducer,
    filters: filterReducer,
    exports: exportReducer,
});

export default rootReducer;
