import * as types from '../constants/action-types';
import * as loadingStatus from '../constants/loading-status';
import * as searchingStatus from '../constants/searching-status';
import { getConsenter, getConsenterTypes } from '../utils/backend';
import { clearUpdatedConsentPreferences } from './update-consent-preferences-actions';
import { clearNewConsentPreference } from './new-consent-preference-actions';

export const loadConsenter = (id, type) => async dispatch => {
    dispatch({
        type: types.UPDATE_CONSENTER_LOAD_STATUS,
        status: loadingStatus.STARTED,
    });
    try {
        const consenter = await getConsenter(id, type, true);
        dispatch({
            type: types.LOAD_CONSENTER,
            consenter: consenter,
        });
    } catch (error) {
        dispatch({
            type: types.UPDATE_CONSENTER_LOAD_STATUS,
            status: loadingStatus.FAILED,
        });
    }
};

export const searchForConsenters = id => async dispatch => {
    dispatch(unsetMatchedConsenters());
    dispatch({
        type: types.UPDATE_CONSENTER_SEARCH_STATUS,
        status: searchingStatus.STARTED,
    });
    try {
        const consenterTypes = await getConsenterTypes();
        const matchedConsenters = (await Promise.all(
            consenterTypes.map(type => getConsenter(id, type, false))
        )).filter(consenter => consenter);

        if (matchedConsenters.length) {
            dispatch({
                type: types.LOAD_MATCHED_CONSENTERS,
                matchedConsenters: matchedConsenters,
            });
        } else {
            dispatch({
                type: types.LOAD_MATCHED_CONSENTERS,
                matchedConsenters: null,
            });
        }
    } catch (error) {
        dispatch({
            type: types.UPDATE_CONSENTER_SEARCH_STATUS,
            status: searchingStatus.FAILED,
        });
    }
};

export const updateConsenterId = consenterId => {
    return {
        type: types.UPDATE_CONSENTER_ID,
        consenterId: consenterId,
    };
};

export const updateConsenterType = consenterType => {
    return {
        type: types.UPDATE_CONSENTER_TYPE,
        consenterType: consenterType,
    };
};

export const unsetConsenter = () => dispatch => {
    dispatch(clearUpdatedConsentPreferences());
    dispatch(clearNewConsentPreference());
    dispatch({
        type: types.UPDATE_CONSENTER_LOAD_STATUS,
        status: loadingStatus.INITIAL,
    });
    return {
        type: types.UNSET_CONSENTER,
    };
};

export const unsetMatchedConsenters = () => {
    return {
        type: types.UNSET_MATCHED_CONSENTERS,
    };
};
