import * as actionTypes from '../constants/action-types';
import { createLoadingAction } from './action-helpers';
import { getFilterOptions } from '../utils/backend';

export const loadFilterOptions = createLoadingAction(
    actionTypes.FILTER_OPTIONS_LOAD_STATUS,
    () => async dispatch => {
        const {
            source_system_names,
            brands,
            program_names,
            consent_types,
            service_names,
            channels,
            consenter_types,
            consentee_names,
        } = await getFilterOptions();
        dispatch({
            type: actionTypes.LOAD_SYSTEMS,
            source_system_names,
        });
        dispatch({
            type: actionTypes.LOAD_BRANDS,
            brands,
        });
        dispatch({
            type: actionTypes.LOAD_PROGRAMS,
            program_names,
        });
        dispatch({
            type: actionTypes.LOAD_CONSENT_TYPES,
            consent_types,
        });
        dispatch({
            type: actionTypes.LOAD_SERVICES,
            service_names,
        });
        dispatch({
            type: actionTypes.LOAD_CHANNELS,
            channels,
        });
        dispatch({
            type: actionTypes.LOAD_CONSENTER_TYPES,
            consenter_types: consenter_types || [],
        });
        dispatch({
            type: actionTypes.LOAD_CONSENTEE_NAMES,
            consentee_names: consentee_names || [],
        });
    }
);

export const selectFilterOption = (fieldName, fieldVal) => async dispatch => {
    dispatch({
        type: actionTypes.SELECT_FILTER_OPTION,
        fieldName,
        fieldVal,
    });
};

export const setCandidateIds = candidateIds => async dispatch => {
    dispatch({
        type: actionTypes.SET_CANDIDATE_IDS,
        candidate_ids: candidateIds,
    });
};
