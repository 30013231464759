import React from 'react';
import PropTypes from 'prop-types';

import store from '../store';
import { checkActivationKey } from '../../../actions/auth-actions';
import ActivationFormContainer from './components/ActivationForm';
import CopyRight from '../Copyright';

const ACTIVATION_KEY_REGEX = /ak=([\d\w]+)/g;

export default class ActivatePage extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
    };

    /**
     * When the page loads, check the activation key
     */
    componentDidMount() {
        const match = ACTIVATION_KEY_REGEX.exec(this.props.location.search);
        const key = (match && match[1]) || null;
        store.dispatch(checkActivationKey(key));
    }

    render() {
        return (
            <div className="registration-page">
                <ActivationFormContainer />
                <CopyRight />
            </div>
        );
    }
}
