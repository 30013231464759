import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { loadAllStats } from '../../../actions/aggregate-stats-actions';
import { loadTotalStats } from '../../../actions/total-consenters-actions';
import { loadFilterOptions } from '../../../actions/filter-actions';
import store from '../store';
import SidebarContainer from './components/Sidebar';
import AggregateStatsContainer from './components/AggregateStats';
import ConsenterModalContainer from './components/ConsenterModal';
import ExportModalContainer from './components/ExportModal';
import UploadModal from './components/UploadModal';
import FilteredCandidatesModal from './components/FilteredCandidatesModal';

class HomePage extends React.Component {
    componentDidMount() {
        if (this.props.isLoggedIn) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.isLoggedIn !== this.props.isLoggedIn &&
            this.props.isLoggedIn
        ) {
            this.loadData();
        }
    }

    loadData() {
        store.dispatch(loadAllStats(this.props.selections));
        store.dispatch(loadFilterOptions());
        store.dispatch(loadTotalStats());
    }

    render() {
        if (['/activate', '/login'].includes(this.props.location.pathname)) {
            return false;
        }
        if (!this.props.isLoggedIn) {
            return <Redirect to="/login" push={true} />;
        }
        return (
            <div className="homepage-container">
                <Route
                    path={['/consenter/:consenterType/:consenterId', '/']}
                    component={ConsenterModalContainer}
                />
                <Route path={'/batch_ingest'} component={UploadModal} />
                <Route
                    path={'/filtered_candidates'}
                    component={FilteredCandidatesModal}
                />
                <div className="dashboard-filters">
                    <SidebarContainer />
                </div>
                <div className="dashboard-content">
                    <AggregateStatsContainer />
                    <ExportModalContainer />
                </div>
            </div>
        );
    }
}

HomePage.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    selections: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        selections: state.filters.selections,
        isLoggedIn: Boolean(state.auth.currentUser),
    };
}

export default connect(mapStateToProps)(HomePage);
