import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Sidebar from './Sidebar';

import { anyInProgress } from '../../../shared/selectors';
import {
    selectFilterOption,
    setCandidateIds,
} from '../../../../../actions/filter-actions';
import {
    filterCandidates,
    loadAllStats,
} from '../../../../../actions/aggregate-stats-actions';

function SidebarContainer(props) {
    const onFilterIds = () => {
        props.onFilterCandidates(props.candidateIds, props.selections);
        props.history.push('/filtered_candidates');
    };

    return (
        <Sidebar
            onChange={props.onFilterChange}
            onCandidatesChange={props.onCandidatesChange}
            onClick={props.onFilterSubmit}
            source_system_names={props.source_system_names}
            brands={props.brands}
            program_names={props.program_names}
            consent_types={props.consent_types}
            service_names={props.service_names}
            consenter_types={props.consenter_types}
            consentee_names={props.consentee_names}
            channels={props.channels}
            selections={props.selections}
            candidateIds={props.candidateIds}
            onFilterIds={onFilterIds}
            isSubmitting={props.isSubmitting}
            isSuperuser={props.isSuperuser}
        />
    );
}

SidebarContainer.propTypes = {
    source_system_names: PropTypes.array.isRequired,
    brands: PropTypes.array.isRequired,
    program_names: PropTypes.array.isRequired,
    consent_types: PropTypes.array.isRequired,
    service_names: PropTypes.array.isRequired,
    consenter_types: PropTypes.array.isRequired,
    consentee_names: PropTypes.array.isRequired,
    channels: PropTypes.array.isRequired,
    onCandidatesChange: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    onFilterSubmit: PropTypes.func.isRequired,
    onFilterCandidates: PropTypes.func.isRequired,
    selections: PropTypes.object.isRequired,
    candidateIds: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isSuperuser: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        source_system_names: state.filters.options.source_system_names,
        brands: state.filters.options.brands,
        program_names: state.filters.options.program_names,
        consent_types: state.filters.options.consent_types,
        service_names: state.filters.options.service_names,
        consenter_types: state.filters.options.consenter_types,
        consentee_names: state.filters.options.consentee_names,
        channels: state.filters.options.channels,
        selections: state.filters.selections,
        candidateIds: state.filters.candidate_ids,
        isSubmitting: anyInProgress(
            state.aggregateStats.aggregateLoadingStatus,
            state.aggregateStats.historyLoadingStatus
        ),
        isSuperuser: state.auth.currentUser.user.is_superuser,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            onFilterChange: selectFilterOption,
            onCandidatesChange: setCandidateIds,
            onFilterSubmit: loadAllStats,
            onFilterCandidates: filterCandidates,
        },
        dispatch
    );
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SidebarContainer)
);
