import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';

import NavigationBarContainer from './Home/components/NavigationBar';
import Home from './Home/';
import Login from './Login/Login';
import Activate from './Activate';
import InitialLoadContainer from './Login/components/InitialLoad/';
import { performInitialLoad } from '../../actions/initial-load-actions';

export default class Root extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // Loads the initial App data
        performInitialLoad()(this.props.store.dispatch);
    }

    render() {
        return (
            <Provider store={this.props.store}>
                <div>
                    <InitialLoadContainer>
                        <Router history={this.props.history}>
                            <div>
                                <Route component={NavigationBarContainer} />
                                <Route path="/" component={Home} />
                                <Route path="/login" component={Login} />
                                <Route path="/activate" component={Activate} />
                            </div>
                        </Router>
                    </InitialLoadContainer>
                </div>
            </Provider>
        );
    }
}
Root.propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object,
};
