import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { formatConsentTypeText } from '../../../../../../../utils/view-helpers';
import StatusCell from '../../../../../shared/StatusCell';
import groupBy from 'lodash.groupby';
import ExpiryDateCell from '../../../../../shared/ExpiryDateCell';

/**
Takes a consenter object and returns a deeply-nested consent preferences
object with each consent preference indexed by consent type, each consent
type indexed by program name, and each program name indexed by brand at the
top level.
 */
function groupTableData(preferences) {
    const tableData = {};
    const preferencesByBrand = groupBy(preferences, 'brand');

    Object.keys(preferencesByBrand).forEach(brand => {
        let preferencesByProgram = groupBy(
            preferencesByBrand[brand],
            'program_name'
        );

        const programRows = {};
        Object.keys(preferencesByProgram).forEach(program => {
            const typeRows = groupBy(
                preferencesByProgram[program],
                ({ consent_type, service_name }) =>
                    consent_type === 'service' && service_name
                        ? `service - ${service_name}`
                        : consent_type
            );
            programRows[program] = typeRows;
        });
        tableData[brand] = programRows;
    });
    return tableData;
}

function totalPreferencesPerConsentType(preferences) {
    return preferences.length;
}

function totalPreferencesPerProgram(programRow) {
    return Object.keys(programRow).reduce((total, consentType) => {
        return total + totalPreferencesPerConsentType(programRow[consentType]);
    }, 0);
}

function totalPreferencesPerBrand(brandRow) {
    return Object.keys(brandRow).reduce((total, program) => {
        return total + totalPreferencesPerProgram(brandRow[program]);
    }, 0);
}

/**
 * Reduces an array of multiValuePreferences into an array of standard consent
 * preferences.
 */
function reduceMultiValuePreferences(multiValuePreferences) {
    return multiValuePreferences.reduce((acc, multiValuePreference) => {
        const {
            brands,
            programs,
            consent_types,
            channels,
            status,
            expiry_date,
        } = multiValuePreference;

        // Any of these can be empty: a null value implies ['All']
        (brands || ['All']).forEach(brand => {
            (programs || ['All']).forEach(program_name => {
                (consent_types || ['All']).forEach(consent_type => {
                    (channels || ['All']).forEach(channel => {
                        acc = [
                            ...acc,
                            {
                                brand,
                                program_name,
                                consent_type,
                                channel,
                                status,
                                expiry_date,
                            },
                        ];
                    });
                });
            });
        });

        return acc;
    }, []);
}

/**
 * Maps an array of preferences into rows for rendering
 */
function mapPreferencesToRows(preferences) {
    const data = groupTableData(preferences);
    const rows = [];
    Object.keys(data)
        .sort()
        .forEach(brand => {
            let cells = [];
            let brandRow = data[brand];
            cells.push(
                <td
                    key={cells.length}
                    className="table-light"
                    rowSpan={totalPreferencesPerBrand(brandRow)}
                >
                    {formatConsentTypeText(brand)}
                </td>
            );
            Object.keys(brandRow)
                .sort()
                .forEach(program => {
                    let programRow = brandRow[program];
                    cells.push(
                        <td
                            key={cells.length}
                            className="table-light"
                            rowSpan={totalPreferencesPerProgram(programRow)}
                        >
                            {formatConsentTypeText(program)}
                        </td>
                    );
                    Object.keys(programRow)
                        .sort()
                        .forEach(consentType => {
                            let consentTypeRow = programRow[consentType];
                            cells.push(
                                <td
                                    key={cells.length}
                                    className="table-light"
                                    rowSpan={totalPreferencesPerConsentType(
                                        consentTypeRow
                                    )}
                                >
                                    {formatConsentTypeText(consentType)}
                                </td>
                            );
                            consentTypeRow.forEach(preference => {
                                cells.push(
                                    <td key={cells.length}>
                                        {formatConsentTypeText(
                                            preference.channel
                                        ) || 'n/a'}
                                    </td>
                                );
                                cells.push(
                                    <td key={cells.length}>
                                        <StatusCell
                                            status={preference.status}
                                        />
                                    </td>
                                );
                                cells.push(
                                    <td key={cells.length}>
                                        <ExpiryDateCell
                                            preference={preference}
                                        />
                                    </td>
                                );
                                rows.push(<tr key={rows.length}>{cells}</tr>);
                                cells = [];
                            });
                        });
                });
        });
    return rows;
}

export default function ConsenterPreferences({
    multiValuePreferences,
    preferences,
}) {
    return (
        <Table
            responsive
            className="table table-bordered table-striped consent-preference-table"
        >
            <tbody>
                <tr>
                    <th> Brand </th>
                    <th> Program </th>
                    <th> Consent type </th>
                    <th> Channel </th>
                    <th> Consent status </th>
                    <th> Expiration </th>
                </tr>
                {/*Render MultiValuePreference rows first*/}
                {mapPreferencesToRows(
                    reduceMultiValuePreferences(multiValuePreferences)
                )}
                {/*Render standard preference rows*/}
                {mapPreferencesToRows(preferences)}
            </tbody>
        </Table>
    );
}

ConsenterPreferences.propTypes = {
    multiValuePreferences: PropTypes.array.isRequired,
    preferences: PropTypes.array.isRequired,
};
