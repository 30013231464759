import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../../../../../actions/auth-actions';
import Menu from './Menu';

const openGuide = () => {
    window.open(
        'https://s3.amazonaws.com/hvlogos/consent/support/ConsentUI_QuickReferenceGuide.pdf',
        '_blank'
    );
};

const contactSupport = () => {
    window.location.href = 'mailto:support@healthverity.com';
};

function MenuContainer(props) {
    const onLogout = () => {
        props.onToggle();
        props.onLogoutSubmit();
    };

    return (
        <Menu
            isOpen={props.isOpen}
            onContactSupport={contactSupport}
            onLogout={onLogout}
            onOpenGuide={openGuide}
            onToggle={props.onToggle}
        />
    );
}

MenuContainer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onLogoutSubmit: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            onLogoutSubmit: actions.logoutUser,
        },
        dispatch
    );
}

export default connect(
    null,
    mapDispatchToProps
)(MenuContainer);
