/**
 * This modal is to upload a file to S3
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Modal, ModalBody, ModalHeader, Input } from 'reactstrap';
import { Link } from 'react-router-dom';

import '../../../../../styles/export-modal.scss';
import './upload-modal.scss';

export default function UploadModal({ onUpload }) {
    let fileObj;
    const onFileChoice = e => {
        fileObj = e.target.files[0];
    };

    let ingest_type = 'preference';
    const onIngestTypeChange = value => {
        ingest_type = value;
    };
    return (
        <Modal className="export-popup" size="sm" isOpen={true}>
            <ModalHeader>Upload data</ModalHeader>
            <ModalBody>
                <Alert color="success">
                    Please select a batch file to ingest:
                </Alert>
                Select ingest file type:
                <form onClick={event => onIngestTypeChange(event.target.value)}>
                    <div>
                        <input
                            id="pref-radio"
                            type="radio"
                            name="ingest-type"
                            value="preference"
                            defaultChecked
                        />{' '}
                        <label htmlFor="pref-radio">Preference File</label>
                    </div>
                    <div>
                        <input
                            id="merge-radio"
                            type="radio"
                            name="ingest-type"
                            value="merge"
                        />{' '}
                        <label htmlFor="merge-radio">Merge File</label>
                    </div>
                </form>
                <Input
                    type="file"
                    name="file"
                    id="choose-upload-file"
                    onChange={onFileChoice}
                />
                <button
                    className="okay"
                    onClick={() => onUpload(fileObj, ingest_type)}
                >
                    Upload
                </button>
                <Link to="/">
                    <button className="cancel">Cancel</button>
                </Link>
            </ModalBody>
        </Modal>
    );
}
UploadModal.propTypes = {
    onUpload: PropTypes.func.isRequired,
};
