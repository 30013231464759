import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export default function Menu(props) {
    return (
        <Modal
            className="menu-modal"
            isOpen={props.isOpen}
            toggle={props.onToggle}
        >
            <ModalHeader>Menu</ModalHeader>
            <ModalBody>
                <button className="dropdown-item" onClick={props.onOpenGuide}>
                    Guide
                </button>
                <div className="dropdown-divider" />
                <button className="dropdown-item" onClick={props.onLogout}>
                    Sign out
                </button>
            </ModalBody>
        </Modal>
    );
}

Menu.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onContactSupport: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    onOpenGuide: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
};
