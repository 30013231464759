import * as types from '../constants/action-types';
const initialState = {
    // The current user
    currentUser: null,
    // Loading status of login
    loginStatus: null,
    // Loading status of logout
    logoutStatus: null,
    // The user's login credentials, as entered by login form
    credentials: {
        username: '',
        password: '',
    },
    activationCredentials: {
        password: '',
        confirmation: '',
    },
    activationStatus: null,
    checkActivationStatus: null,
    activationKey: null,
    email: '',
    hasWritePermission: false,
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case types.LOGIN_USER:
            return Object.assign({}, state, {
                currentUser: action.user,
                username: null,
                password: null,
                email: action.email,
            });
        case types.UPDATE_LOGIN_STATUS:
            return Object.assign({}, state, { loginStatus: action.status });
        case types.LOGOUT_USER:
            return Object.assign({}, state, { currentUser: null });
        case types.UPDATE_LOGIN_CREDENTIALS:
            return Object.assign({}, state, {
                credentials: {
                    username: action.username,
                    password: action.password,
                },
            });
        case types.UPDATE_ACTIVATION_CREDENTIALS:
            return Object.assign({}, state, {
                activationCredentials: {
                    password: action.password,
                    confirmation: action.confirmation,
                },
                activationStatus: null,
            });
        case types.UPDATE_ACTIVATION_STATUS:
            return Object.assign({}, state, {
                activationStatus: action.status,
            });
        case types.UPDATE_CHECK_ACTIVATION_STATUS:
            return Object.assign({}, state, {
                checkActivationStatus: action.status,
            });
        case types.SET_ACTIVATION_KEY:
            return Object.assign({}, state, {
                activationKey: action.activationKey,
            });
        case types.SET_HAS_WRITE_PERMISSION:
            return Object.assign({}, state, {
                hasWritePermission: action.hasWritePermission,
            });
        default:
            return state;
    }
}
