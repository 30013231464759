import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Alert, Modal, ModalHeader, ModalBody } from 'reactstrap';

import * as loadingStatus from '../../../../../constants/loading-status';
import * as searchingStatus from '../../../../../constants/searching-status';
import ConsenterPreferences from './components/ConsenterPreferences';
import ConsenterHistoryTableContainer from '../ConsenterHistoryTable';
import { prettifyConsenterTypeText } from '../../../../../utils/view-helpers';
import { BASE_URL } from '../../../../../utils/backend';
import UpdateConsentPreferencesModal from './components/UpdateConsentPreferencesModal';
import AddNewConsentPreferenceModal from './components/AddNewConsentPreferenceModal';
import MergeIdList from './components/MergeIdList';

class ConsenterModal extends React.Component {
    renderSearchStarted = () => {
        return {
            header: 'Searching for consenter...',
            body: (
                <Alert color="secondary">
                    Searching for consenter with id {this.props.consenterId}
                </Alert>
            ),
            modalClass: 'consenter-id-search-results',
            modalSize: 'md',
        };
    };

    renderSearchSucceeded = () => {
        const modalClass = 'consenter-id-search-results';
        const modalSize = 'md';
        if (this.props.matchedConsenters) {
            if (this.props.matchedConsenters.length === 1) {
                const {
                    consenter_id,
                    consenter_type,
                } = this.props.matchedConsenters[0];
                return {
                    body: (
                        <Redirect
                            push={true}
                            to={`/consenter/${consenter_type}/${consenter_id}`}
                        />
                    ),
                };
            } else {
                const consenterOptions = this.props.matchedConsenters.map(
                    ({ consenter_type, consenter_id }) => (
                        <button
                            className="consenter"
                            key={`${consenter_type} ${consenter_id}`}
                            onClick={() =>
                                this.props.loadConsenter(
                                    consenter_id,
                                    consenter_type
                                )
                            }
                        >
                            {prettifyConsenterTypeText(consenter_type)}{' '}
                            {consenter_id}
                        </button>
                    )
                );

                return {
                    header: `Search results for "${this.props.consenterId}"`,
                    body: (
                        <div>
                            <Alert color="success">
                                {
                                    'The following results were found with this ID:'
                                }
                            </Alert>
                            <div>
                                <div className="consenter-options">
                                    {consenterOptions}
                                </div>
                            </div>
                            <button
                                className="cancel"
                                onClick={this.props.toggle}
                            >
                                Cancel
                            </button>
                        </div>
                    ),
                    modalClass,
                    modalSize,
                };
            }
        } else {
            return {
                header: `"${this.props.consenterId}" not found`,
                body: (
                    <div>
                        <Alert color="success">
                            No patient or provider matching this ID was found.
                            Please check that the ID was entered correctly.
                        </Alert>
                        <button className="okay" onClick={this.props.toggle}>
                            Okay
                        </button>
                    </div>
                ),
                modalClass,
                modalSize,
            };
        }
    };

    renderSearchingFailedStatus = () => {
        return {
            header: 'Searching failed',
            body: (
                <Alert color="danger">
                    There was an error processing your request. Please contact
                    customer support.
                </Alert>
            ),
            modalClass: 'consenter-id-search-results',
            modalSize: 'md',
        };
    };

    renderLoadingStartedStatus = () => {
        return {
            header: 'Loading consenter...',
            body: (
                <Alert color="secondary">
                    Loading consenter with id {this.props.consenterId}
                </Alert>
            ),
            modalClass: 'consenter-id-search-results',
            modalSize: 'md',
        };
    };

    renderLoadingSucceededStatus = () => {
        if (this.props.consenter) {
            const consenterType = prettifyConsenterTypeText(
                this.props.consenter.consenter_type
            );
            const link = this.props.consenter.link;
            return {
                header: (
                    <div>
                        Known consent preferences - {consenterType}{' '}
                        {this.props.consenter.consenter_id}
                        <MergeIdList consenter={this.props.consenter} />{' '}
                        {link && (
                            <a
                                href={`${BASE_URL}${link}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span className="glyphicons glyphicons-download consent-preference-export-link" />
                            </a>
                        )}
                        <span className="consent-preference-crud-container">
                            {this.props.hasWritePermission && (
                                <div>
                                    <button
                                        className="consent-preference-crud-button"
                                        onClick={
                                            this.props
                                                .requestUpdateConsentPreferences
                                        }
                                    >
                                        <span
                                            aria-hidden={true}
                                            className="consent-preference-crud-icon glyphicons glyphicons-pencil"
                                        />
                                        Update
                                    </button>
                                    <button
                                        className="consent-preference-crud-button"
                                        onClick={
                                            this.props
                                                .requestNewConsentPreference
                                        }
                                    >
                                        <span
                                            aria-hidden={true}
                                            className="consent-preference-crud-icon glyphicons glyphicons-plus"
                                        />
                                        Add New
                                    </button>
                                </div>
                            )}
                        </span>
                    </div>
                ),
                body: (
                    <div>
                        <ConsenterPreferences
                            preferences={this.props.consenter.preferences}
                            multiValuePreferences={
                                this.props.consenter.multi_value_preferences
                            }
                        />
                        <hr />
                        <div className="table-title">
                            {consenterType} history
                        </div>
                        <ConsenterHistoryTableContainer />
                    </div>
                ),
                modalClass: 'consenter-modal',
                modalSize: 'lg',
            };
        } else {
            const consenterType = prettifyConsenterTypeText(
                this.props.consenterType
            ).toLowerCase();

            return {
                header: `"${this.props.consenterId}" not found`,
                body: (
                    <div>
                        <Alert color="success">
                            No {consenterType} matching this ID was found.
                            Please check that the ID was entered correctly.
                        </Alert>
                        <button className="okay" onClick={this.props.toggle}>
                            Okay
                        </button>
                    </div>
                ),
                modalClass: 'consenter-id-search-results',
                modalSize: 'md',
            };
        }
    };

    renderLoadingFailedStatus = () => {
        return {
            header: 'Loading failed',
            body: (
                <Alert color="danger">
                    There was an error processing your request. Please contact
                    customer support.
                </Alert>
            ),
            modalClass: 'consenter-id-search-results',
            modalSize: 'md',
        };
    };

    render() {
        let header, body, modalClass, modalSize;

        switch (this.props.status) {
            case searchingStatus.STARTED:
                ({
                    header,
                    body,
                    modalClass,
                    modalSize,
                } = this.renderSearchStarted());
                break;
            case searchingStatus.SUCCEEDED:
                ({
                    header,
                    body,
                    modalClass,
                    modalSize,
                } = this.renderSearchSucceeded());
                break;
            case searchingStatus.FAILED:
                ({
                    header,
                    body,
                    modalClass,
                    modalSize,
                } = this.renderSearchingFailedStatus());
                break;
            case loadingStatus.STARTED:
                ({
                    header,
                    body,
                    modalClass,
                    modalSize,
                } = this.renderLoadingStartedStatus());
                break;
            case loadingStatus.SUCCEEDED:
                ({
                    header,
                    body,
                    modalClass,
                    modalSize,
                } = this.renderLoadingSucceededStatus());
                break;
            case loadingStatus.FAILED:
                ({
                    header,
                    body,
                    modalClass,
                    modalSize,
                } = this.renderLoadingFailedStatus());
                break;
            default:
                return null;
        }

        return (
            <Modal
                className={modalClass}
                size={modalSize}
                isOpen={Boolean(this.props.status)}
                toggle={this.props.toggle}
            >
                <ModalHeader toggle={this.props.toggle}>{header}</ModalHeader>
                <ModalBody>
                    {body}
                    <AddNewConsentPreferenceModal {...this.props} />
                    <UpdateConsentPreferencesModal {...this.props} />
                </ModalBody>
            </Modal>
        );
    }
}

ConsenterModal.propTypes = {
    consenter: PropTypes.object,
    consenterId: PropTypes.string,
    consenterType: PropTypes.string,
    status: PropTypes.string,
    matchedConsenters: PropTypes.array,
    loadConsenter: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    requestUpdateConsentPreferences: PropTypes.func.isRequired,
    requestNewConsentPreference: PropTypes.func.isRequired,
};

export default ConsenterModal;
