import * as types from '../constants/action-types';
import { FAILED, INITIAL, REJECTED } from '../constants/loading-status';
import { TOTAL_CONSENTER_TILE } from '../constants/tile-names';
import moment from 'moment';

const today = moment().format('MMM DD, YYYY');

const defaultStartDate = moment()
    .subtract(2, 'months')
    .format('MMM DD, YYYY');

const initialState = {
    consenters: {
        granted: { value: null, status: INITIAL },
        revoked: { value: null, status: INITIAL },
        unknown: { value: null, status: INITIAL },
        global_opt_outs: { value: null, status: INITIAL },
        filtered_consenters: { value: null, status: INITIAL },
        expire_in_365: {
            value: { total: null, link: null },
            status: INITIAL,
        },
        expire_in_180: {
            value: { total: null, link: null },
            status: INITIAL,
        },
        expire_in_60: {
            value: { total: null, link: null },
            status: INITIAL,
        },
        expired: {
            value: { total: null, link: null },
            status: INITIAL,
        },
    },
    lastFilterSelections: {},
    metrics: [],
    dailyHistoryStats: [],
    transactionTotals: {
        granted: null,
        revoked: null,
        unknown: null,
        'global opt-outs': null,
    },
    aggregateLoadingStatus: null,
    historyLoadingStatus: null,
    startDate: defaultStartDate,
    endDate: today,
    selectedTile: TOTAL_CONSENTER_TILE,
};

/**
 * Generic function to generate new state from the given state, updating the
 * properties of the 'consenters' state object with the given key and value
 */
const updateAggregateStatValue = (state, key, value) => ({
    ...state,
    ...{
        consenters: {
            ...state.consenters,
            [key]: { ...state.consenters[key], ...value },
        },
    },
});

/**
 * Generic function to generate new state from the given state, updating the
 * loading status of the 'consenters' state object with the given key
 */
const updateAggregateStatStatus = (state, key, status) => {
    if ([REJECTED, FAILED].includes(status)) {
        return updateAggregateStatValue(state, key, {
            value: null,
            status: status,
        });
    } else {
        return updateAggregateStatValue(state, key, {
            status: status,
        });
    }
};

export function aggregateStatsReducer(state = initialState, action) {
    switch (action.type) {
        // Update "Total Consenters Updated" title to reflect date range
        case types.CACHE_FILTER_VALUES:
            var formattedStart = moment(action.startDate).format(
                'MMM DD, YYYY'
            );
            var formattedEnd = moment(action.endDate).format('MMM DD, YYYY');
            return Object.assign({}, state, {
                startDate: formattedStart,
                endDate: formattedEnd,
                lastFilterSelections: action.filterOptions,
            });
        // Consenters filtered by ID
        case types.LOAD_FILTERED_CONSENTERS:
            return updateAggregateStatValue(state, 'filtered_consenters', {
                value: action.filtered_consenters,
            });
        case types.LOAD_FILTERED_CONSENTERS_STATUS:
            return updateAggregateStatStatus(
                state,
                'filtered_consenters',
                action.status
            );
        // "Granted" status actions
        case types.LOAD_FILTERED_CONSENTERS_GRANTED:
            return updateAggregateStatValue(state, 'granted', {
                value: action.granted,
            });
        case types.LOAD_FILTERED_CONSENTERS_GRANTED_STATUS:
            return updateAggregateStatStatus(state, 'granted', action.status);
        // "Revoked" status actions
        case types.LOAD_FILTERED_CONSENTERS_REVOKED:
            return updateAggregateStatValue(state, 'revoked', {
                value: action.revoked,
            });
        case types.LOAD_FILTERED_CONSENTERS_REVOKED_STATUS:
            return updateAggregateStatStatus(state, 'revoked', action.status);
        // "Unknown" status actions
        case types.LOAD_FILTERED_CONSENTERS_UNKNOWN:
            return updateAggregateStatValue(state, 'unknown', {
                value: action.unknown,
            });
        case types.LOAD_FILTERED_CONSENTERS_UNKNOWN_STATUS:
            return updateAggregateStatStatus(state, 'unknown', action.status);
        // "Global opt out" status actions
        case types.LOAD_FILTERED_GLOBAL_OPT_OUTS:
            return updateAggregateStatValue(state, 'global_opt_outs', {
                value: action.global_opt_outs,
            });
        case types.LOAD_FILTERED_GLOBAL_OPT_OUTS_STATUS:
            return updateAggregateStatStatus(
                state,
                'global_opt_outs',
                action.status
            );
        // "Consenters expire in 365 days" status actions
        case types.LOAD_FILTERED_CONSENTERS_EXPIRING_365_DAYS:
            return updateAggregateStatValue(state, 'expire_in_365', {
                value: action.expire_in_365,
            });
        case types.LOAD_FILTERED_CONSENTERS_EXPIRING_365_DAYS_STATUS:
            return updateAggregateStatStatus(
                state,
                'expire_in_365',
                action.status
            );
        // "Consenters expire in 180 days" status actions
        case types.LOAD_FILTERED_CONSENTERS_EXPIRING_180_DAYS:
            return updateAggregateStatValue(state, 'expire_in_180', {
                value: action.expire_in_180,
            });
        case types.LOAD_FILTERED_CONSENTERS_EXPIRING_180_DAYS_STATUS:
            return updateAggregateStatStatus(
                state,
                'expire_in_180',
                action.status
            );
        // "Consenters expire in 60 days" status actions
        case types.LOAD_FILTERED_CONSENTERS_EXPIRING_60_DAYS:
            return updateAggregateStatValue(state, 'expire_in_60', {
                value: action.expire_in_60,
            });
        case types.LOAD_FILTERED_CONSENTERS_EXPIRING_60_DAYS_STATUS:
            return updateAggregateStatStatus(
                state,
                'expire_in_60',
                action.status
            );
        // "Expired consenters" actions
        case types.LOAD_FILTERED_CONSENTERS_EXPIRED:
            return updateAggregateStatValue(state, 'expired', {
                value: action.expired,
            });
        case types.LOAD_FILTERED_CONSENTERS_EXPIRED_STATUS:
            return updateAggregateStatStatus(state, 'expired', action.status);
        // "Daily history stats" actions
        case types.LOAD_DAILY_HISTORY_STATS:
            var transactionTotals = {
                granted: 0,
                revoked: 0,
                unknown: 0,
                'global opt-outs': 0,
            };

            for (let dailyStat of action.dailyHistoryStats) {
                for (let statValue of dailyStat.values) {
                    transactionTotals[statValue.name.toLowerCase()] +=
                        statValue.value;
                }
            }
            return Object.assign({}, state, {
                transactionTotals: transactionTotals,
                dailyHistoryStats: action.dailyHistoryStats,
            });
        case types.LOAD_DAILY_HISTORY_STATS_STATUS:
            return Object.assign({}, state, {
                historyLoadingStatus: action.status,
            });
        case types.SELECT_DASHBOARD_TILE:
            return Object.assign({}, state, {
                selectedTile: action.tileName,
            });
        default:
            return state;
    }
}

const totalStatsInitialState = {
    consenters: {
        granted: { value: null, status: INITIAL },
        revoked: { value: null, status: INITIAL },
        unknown: { value: null, status: INITIAL },
        global_opt_outs: { value: null, status: INITIAL },
    },
};
export function totalStatsReducer(state = totalStatsInitialState, action) {
    switch (action.type) {
        // "Granted" status actions
        case types.LOAD_TOTAL_CONSENTERS_GRANTED:
            return updateAggregateStatValue(state, 'granted', {
                value: action.granted,
            });
        case types.LOAD_TOTAL_CONSENTERS_GRANTED_STATUS:
            return updateAggregateStatStatus(state, 'granted', action.status);
        // "Revoked" status actions
        case types.LOAD_TOTAL_CONSENTERS_REVOKED:
            return updateAggregateStatValue(state, 'revoked', {
                value: action.revoked,
            });
        case types.LOAD_TOTAL_CONSENTERS_REVOKED_STATUS:
            return updateAggregateStatStatus(state, 'revoked', action.status);
        // "Unknown" status actions
        case types.LOAD_TOTAL_CONSENTERS_UNKNOWN:
            return updateAggregateStatValue(state, 'unknown', {
                value: action.unknown,
            });
        case types.LOAD_TOTAL_CONSENTERS_UNKNOWN_STATUS:
            return updateAggregateStatStatus(state, 'unknown', action.status);
        // "Global opt out" status actions
        case types.LOAD_TOTAL_GLOBAL_OPT_OUTS:
            return updateAggregateStatValue(state, 'global_opt_outs', {
                value: action.global_opt_outs,
            });
        case types.LOAD_TOTAL_GLOBAL_OPT_OUTS_STATUS:
            return updateAggregateStatStatus(
                state,
                'global_opt_outs',
                action.status
            );
        default:
            return state;
    }
}
