import * as types from '../constants/action-types';
import * as loadingStatus from '../constants/loading-status';
import * as searchingStatus from '../constants/searching-status';

const initialState = {
    consenter: null,
    status: null,
    consenterId: '',
    matchedConsenters: null,
};

export default function consenterReducer(state = initialState, action) {
    switch (action.type) {
        case types.CONSENTER_NOT_FOUND:
            return {
                ...state,
                consenter: null,
            };
        case types.LOAD_CONSENTER:
            return {
                ...state,
                status: loadingStatus.SUCCEEDED,
                consenter: action.consenter,
                matchedConsenters: null,
            };
        case types.LOAD_MATCHED_CONSENTERS:
            return {
                ...state,
                status: searchingStatus.SUCCEEDED,
                matchedConsenters: action.matchedConsenters,
            };
        case types.UPDATE_CONSENTER_LOAD_STATUS:
        case types.UPDATE_CONSENTER_SEARCH_STATUS:
            return {
                ...state,
                status: action.status,
            };
        case types.UPDATE_CONSENTER_ID:
            return {
                ...state,
                consenterId: action.consenterId,
            };
        case types.UPDATE_CONSENTER_TYPE:
            return {
                ...state,
                consenterType: action.consenterType,
            };
        case types.UNSET_CONSENTER:
            return {
                ...state,
                status: null,
                consenter: null,
            };
        case types.UNSET_MATCHED_CONSENTERS:
            return {
                ...state,
                status: null,
                consenter: null,
                matchedConsenters: null,
            };
        default:
            return state;
    }
}
