import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import Moment from 'react-moment';

export default function ConsenterHistoryTable(props) {
    const consenterHistoryRows = props.consenterHistoryRecords.map(
        (record, i) => (
            <tr
                className={
                    record['event_type'] ===
                    'multi_value_preference_added_history'
                        ? 'align-top'
                        : ''
                }
                key={i}
            >
                <td>
                    <Moment parse="YYYY-MM-DD HH:mm:SS" format="MM/DD/YY">
                        {record['transaction_date']}
                    </Moment>
                </td>
                <td>{record['consenter_id']}</td>
                <td>{record['event']}</td>
                <td>
                    <Moment parse="YYYY-MM-DD HH:mm:SS" format="MM/DD/YY">
                        {record['event_time']}
                    </Moment>
                </td>
                <td>{record['source_system']}</td>
            </tr>
        )
    );

    return (
        <Table striped borderless>
            <thead>
                <tr>
                    <th>Event date</th>
                    <th>Consenter ID</th>
                    <th>Event</th>
                    <th>System update date</th>
                    <th>Source system</th>
                </tr>
            </thead>
            <tbody>{consenterHistoryRows}</tbody>
        </Table>
    );
}

ConsenterHistoryTable.propTypes = {
    consenterHistoryRecords: PropTypes.array.isRequired,
};
