import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import Moment from 'react-moment';

import LoadingSpinner from '../../../../../shared/LoadingSpinner';

import { formatNumber } from '../../../../../../../utils/view-helpers';

function DailyActivityTable(props) {
    const renderErrorFileCell = (
        date,
        errorFileInfo,
        errorFileInfoIsLoading
    ) => {
        let errorFileElement = <div>--</div>;
        if (errorFileInfo && errorFileInfo[date]) {
            const hasErrorFile = errorFileInfo[date]['has_files'];
            if (hasErrorFile) {
                const errorFileLink =
                    process.env.CONSENT_ADMIN_BACKEND_BASE_URL +
                    errorFileInfo[date]['link'];
                errorFileElement = (
                    <a
                        href={errorFileLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Error Files
                    </a>
                );
            }
        }

        return (
            <td>
                <LoadingSpinner
                    isLoading={errorFileInfoIsLoading}
                    component={() => errorFileElement}
                />
            </td>
        );
    };

    const dailyHistoryStats = props.dailyHistoryStats.map(
        ({ date, delta_link, values }, i) => {
            const valuesByStatus = values.reduce(
                (acc, value) =>
                    Object.assign(acc, {
                        [value.name]: formatNumber(value.value),
                    }),
                {}
            );

            const deltaFileLink =
                process.env.CONSENT_ADMIN_BACKEND_BASE_URL + delta_link;
            return (
                <tr key={i}>
                    <td>
                        <Moment format="LL">{date}</Moment>
                    </td>
                    <td>{valuesByStatus['Granted']}</td>
                    <td>{valuesByStatus['Revoked']}</td>
                    <td>{valuesByStatus['Merges']}</td>
                    <td>{valuesByStatus['Unmerges']}</td>
                    <td>{valuesByStatus['Global Opt-outs']}</td>
                    {renderErrorFileCell(
                        date,
                        props.errorFileInfo,
                        props.errorFileInfoIsLoading
                    )}
                    {props.isSuperuser && (
                        <td>
                            <a
                                href={deltaFileLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Delta file
                            </a>
                        </td>
                    )}
                </tr>
            );
        }
    );

    return (
        <Table striped borderless>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Grants</th>
                    <th>Revokes</th>
                    <th>Merges</th>
                    <th>Unmerges</th>
                    <th>Global Opt-outs</th>
                    <th />
                    {props.isSuperuser && <th />}
                </tr>
            </thead>
            <tbody>{dailyHistoryStats}</tbody>
        </Table>
    );
}

DailyActivityTable.propTypes = {
    errorFileInfo: PropTypes.object.isRequired,
    errorFileInfoIsLoading: PropTypes.bool.isRequired,
    dailyHistoryStats: PropTypes.array.isRequired,
    isSuperuser: PropTypes.bool.isRequired,
};

export default DailyActivityTable;
