import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UploadModal from './UploadModal';
import { uploadBatchIngestFile } from '../../../../../utils/backend';

class UploadModalContainer extends Component {
    uploadFile = (file, ingest_type) => {
        uploadBatchIngestFile(file, ingest_type);
        this.props.history.push('/');
    };

    render() {
        return <UploadModal onUpload={this.uploadFile} />;
    }
}
UploadModalContainer.propTypes = {
    history: PropTypes.object.isRequired,
};

export default UploadModalContainer;
