/**
 * Reducer for storing state data relevant to exporting consenters
 */

import * as types from '../constants/action-types';

/**
 * Initial state for this reducer
 */
const initialState = {
    // The active export status. If null, no status is selected
    selectedExportStatus: null,
    // The status of sending an export (loading, completed, etc)
    sendExportStatus: null,
};

/**
 * Reducer for the "export" store
 */
export default function exportReducer(state = initialState, action) {
    switch (action.type) {
        case types.SEND_EXPORT_STATUS:
            return { ...state, sendExportStatus: action.status };
        case types.SET_SELECTED_EXPORT_STATUS:
            return {
                ...state,
                selectedExportStatus: action.status,
                sendExportStatus: null,
            };
        case types.DESELECT_EXPORT_STATUS:
            return { ...state, selectedExportStatus: null };
        default:
            return state;
    }
}
