import * as loadingStatus from '../constants/loading-status';

/**
 * Generates an action creator that automatically its the loading status, by
 * doing the following:
 *
 * 1) Immediately dispatches a "STARTED" action of type `actionType`
 * 2) Executes the wrapped `actionCreator`
 * 3) If no errors are thrown, dispatches a "SUCCEEDED" action of type `actionType`
 * 4) If a 4XX error is thrown, dispatches a "REJECTED" action of type `actionType`
 * 5) If any other error is thrown, dispatches a "FAILED" action of type `actionType`
 *
 * @example
 *     import * as types from '../constants/action-types';
 *     const makeBackendCall = createLoadingAction(
 *         types.UPDATE_BACKEND_CALL_STATUS,
 *         params => async dispatch => {
 *             dispatch({
 *                 type: types.UPDATE_DATA,
 *                 data: await getSomeData(params)
 *             })
 *         }
 *     )
 *
 *
 *     store.dispatch(makeBackendCall({param1: 'value'}));

 *     // Success actions:
 *     // => {type: 'UPDATE_BACKEND_CALL_STATUS', status: 'STARTED'}
 *     // => {type: 'UPDATE_DATA', data: {...}}
 *     // => {type: 'UPDATE_BACKEND_CALL_STATUS', status: 'SUCCEEDED'}
 *     // Failure actions:
 *     // => {type: 'UPDATE_BACKEND_CALL_STATUS', status: 'STARTED'}
 *     // => {type: 'UPDATE_BACKEND_CALL_STATUS', status: 'FAILED'}
 */
export const createLoadingAction = (actionType, actionCreator) => (
    ...args
) => async dispatch => {
    // Dispatch an initial STARTED action
    dispatch({
        type: actionType,
        status: loadingStatus.STARTED,
    });
    try {
        const intiializedAC = await actionCreator(...args);
        await intiializedAC(dispatch);
        // If no error, dispatch a SUCCEEDED action
        dispatch({
            type: actionType,
            status: loadingStatus.SUCCEEDED,
        });
    } catch (error) {
        // If there was an HTTP error that was in the 400 range, dispatch a
        // REJECTED action
        if (error.response && error.response.status < 500) {
            return dispatch({
                type: actionType,
                status: loadingStatus.REJECTED,
            });
        }

        // For any other error, dispath a FAILED action
        return dispatch({
            type: actionType,
            status: loadingStatus.FAILED,
        });
    }
};
