import React from 'react';

export default function CopyRight() {
    const year = new Date().getFullYear();
    return (
        <span className="copyright">
            © {year} HealthVerity Inc. All rights reserved.
        </span>
    );
}
