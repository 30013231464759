// INITIAL signifies that the request has not yet started
export const INITIAL = 'INITIAL';
// SUCCEEDED signifies that the request has been started
export const STARTED = 'LOADING STARTED';
// SUCCEEDED signifies that the request was a success
export const SUCCEEDED = 'LOADING SUCCEEDED';
// REJECTED signifies that a request was explicitly rejects
export const REJECTED = 'LOADING REJECTED';
// FAILED signifies that the request encountered an error
export const FAILED = 'LOADING FAILED';
