import React from 'react';
import PropTypes from 'prop-types';
import { prettifyConsenterTypeText } from '../../../../../../../utils/view-helpers';

export default function MergeIdList({ consenter }) {
    if (consenter) {
        // Filter out the canonical consenter ID
        const consenterId = consenter.consenter_id;
        const mergeIds = (consenter.merge_ids || []).filter(
            mid => mid !== consenterId.toString()
        );
        if (mergeIds.length > 0) {
            return (
                <h6>
                    <em>{`(Formerly ${prettifyConsenterTypeText(
                        consenter.consenter_type
                    ).toLowerCase()}s ${mergeIds.join(', ')})`}</em>
                </h6>
            );
        }
    }
    return null;
}

MergeIdList.propTypes = {
    consenter: PropTypes.object,
};
