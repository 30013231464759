import * as types from '../constants/action-types';
import * as loadingStatus from '../constants/loading-status';

const initialState = {
    requested: false,
    preferences: {
        /* [preferenceCompositeKey]: { consentStatus, expiryDate },… */
    },
    config: {
        consentStatuses: ['granted', 'revoked', 'expired'],
        consentTypesRequiringExpiry: ['hipaa'],
    },
    submitting: false,
};

export default function updateConsentPreferencesReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case types.UPDATE_CONSENT_PREFERENCES_REQUEST:
            return {
                ...state,
                preferences: {},
                requested: true,
            };
        case types.UPDATE_CONSENT_PREFERENCES_CLEAR:
            return {
                ...state,
                preferences: {},
                requested: false,
            };
        case types.UPDATE_CONSENT_PREFERENCES_CONSENT_STATUS_UPDATED:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    [action.preferenceCompositeKey]: {
                        ...(state.preferences[action.preferenceCompositeKey] ||
                            {}),
                        consentStatus: action.consentStatus,
                        expiryDate:
                            action.consentStatus === 'revoked'
                                ? ''
                                : (
                                      state.preferences[
                                          action.preferenceCompositeKey
                                      ] || {}
                                  ).expiryDate,
                    },
                },
            };
        case types.UPDATE_CONSENT_PREFERENCES_EXPIRY_DATE_UPDATED:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    [action.preferenceCompositeKey]: {
                        ...(state.preferences[action.preferenceCompositeKey] ||
                            {}),
                        expiryDate:
                            (
                                state.preferences[
                                    action.preferenceCompositeKey
                                ] || {}
                            ).consentStatus === 'revoked'
                                ? (
                                      state.preferences[
                                          action.preferenceCompositeKey
                                      ] || {}
                                  ).expiryDate
                                : action.expiryDate,
                    },
                },
            };
        case types.UPDATE_CONSENT_PREFERENCES_SUBMIT_STATUS:
            return {
                ...state,
                submitting: action.status === loadingStatus.STARTED,
            };
        default:
            return state;
    }
}
