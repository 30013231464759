import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ConsenterModal from './ConsenterModal';
import { checkHasWritePermission } from '../../../../../actions/auth-actions';
import * as consenterActions from '../../../../../actions/consenter-actions';
import * as newConsentPreferenceActions from '../../../../../actions/new-consent-preference-actions';
import * as updateConsentPreferencesActions from '../../../../../actions/update-consent-preferences-actions';

class ConsenterModalContainer extends Component {
    componentDidMount() {
        this.syncConsenterToLocation();
    }

    shouldComponentUpdate(nextProps) {
        const {
            status,
            match: { url },
            updateConsentPreferencesRequested,
            newConsentPreferenceRequested,
        } = this.props;

        const {
            status: nextStatus,
            match: { url: nextUrl },
            updateConsentPreferencesRequested: nextUpdateConsentPreferencesRequested,
            newConsentPreferenceRequested: nextNewConsentPreferenceRequested,
        } = nextProps;

        const statusUnchanged = status === nextStatus,
            urlUnchanged = url === nextUrl,
            updatePreferenceRequestUnchanged =
                updateConsentPreferencesRequested ===
                nextUpdateConsentPreferencesRequested,
            newPreferenceRequestUnchanged =
                newConsentPreferenceRequested ===
                nextNewConsentPreferenceRequested;

        return !(
            statusUnchanged &&
            urlUnchanged &&
            updatePreferenceRequestUnchanged &&
            newPreferenceRequestUnchanged
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.url !== this.props.match.url) {
            this.syncConsenterToLocation();
        }
    }

    loadConsenter = (id, type) => {
        this.props.history.push(`/consenter/${type}/${id}`);
    };

    toggle = () => {
        if (Object.keys(this.props.match.params).length) {
            this.props.history.push('/');
        } else {
            this.props.unsetConsenter();
        }
    };

    syncConsenterToLocation = () => {
        if (Object.keys(this.props.match.params).length) {
            const { consenterId, consenterType } = this.props.match.params;
            this.props.updateConsenterId(consenterId);
            this.props.updateConsenterType(consenterType);
            this.props.loadConsenter(consenterId, consenterType);
            this.props.checkHasWritePermission();
        } else {
            this.props.unsetConsenter();
        }
    };

    render() {
        return (
            <ConsenterModal
                {...this.props}
                toggle={this.toggle}
                loadConsenter={this.loadConsenter}
            />
        );
    }
}

ConsenterModalContainer.propTypes = {
    consenter: PropTypes.object,
    consenterId: PropTypes.string,
    consenterType: PropTypes.string,
    matchedConsenters: PropTypes.array,
    status: PropTypes.string,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    unsetConsenter: PropTypes.func.isRequired,
    updateConsenterId: PropTypes.func.isRequired,
    updateConsenterType: PropTypes.func.isRequired,
    loadConsenter: PropTypes.func.isRequired,
    newConsentPreferenceRequested: PropTypes.bool.isRequired,
    requestNewConsentPreference: PropTypes.func.isRequired,
    updateConsentPreferencesRequested: PropTypes.bool.isRequired,
    requestUpdateConsentPreferences: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        consenter: state.consenter.consenter,
        consenterId: state.consenter.consenterId,
        consenterType: state.consenter.consenterType,
        matchedConsenters: state.consenter.matchedConsenters,
        status: state.consenter.status,
        newConsentPreferenceRequested: state.newConsentPreference.requested,
        updateConsentPreferencesRequested:
            state.updateConsentPreferences.requested,
        hasWritePermission: state.auth.hasWritePermission,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            loadConsenter: consenterActions.loadConsenter,
            updateConsenterId: consenterActions.updateConsenterId,
            updateConsenterType: consenterActions.updateConsenterType,
            unsetConsenter: consenterActions.unsetConsenter,
            unsetMatchedConsenters: consenterActions.unsetMatchedConsenters,
            requestNewConsentPreference:
                newConsentPreferenceActions.requestNewConsentPreference,
            requestUpdateConsentPreferences:
                updateConsentPreferencesActions.requestUpdateConsentPreferences,
            checkHasWritePermission,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConsenterModalContainer);
