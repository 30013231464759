import * as types from '../constants/action-types';
import * as loadingStatus from '../constants/loading-status';
import { loadCurrentUser } from '../utils/backend';

export const performInitialLoad = () => async dispatch => {
    dispatch({
        type: types.UPDATE_INITIAL_LOAD_STATUS,
        status: loadingStatus.STARTED,
    });
    try {
        dispatch({
            type: types.LOGIN_USER,
            user: await loadCurrentUser(),
        });
        dispatch({
            type: types.UPDATE_INITIAL_LOAD_STATUS,
            status: loadingStatus.SUCCEEDED,
        });
    } catch (error) {
        dispatch({
            type: types.UPDATE_INITIAL_LOAD_STATUS,
            status: loadingStatus.FAILED,
        });
    }
};

export const updateLoginCredentials = ({ username, password }) => ({
    type: types.UPDATE_LOGIN_CREDENTIALS,
    username,
    password,
});
