// Set up your application entry point here...
import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import store, { history } from './screens/App/store';
import Root from './screens/App/Root';
import { initializeGoogleAnalytics } from './actions/analytics';
import './styles/glyphicons/css/glyphicons.css';
import './styles/styles.scss'; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
require('./screens/App/shared/static/favicon.ico'); // Tell webpack to load favicon.ico
require('./screens/App/shared/static/hv_favicon.png'); // Tell webpack to load hv_favicon.png

initializeGoogleAnalytics(history);

render(
    <AppContainer>
        <Root store={store} history={history} />
    </AppContainer>,
    document.getElementById('app')
);

if (module.hot) {
    module.hot.accept('./screens/App/Root', () => {
        const NewRoot = require('./screens/App/Root').default;
        render(
            <AppContainer>
                <NewRoot store={store} history={history} />
            </AppContainer>,
            document.getElementById('app')
        );
    });
}
