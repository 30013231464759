import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import NavigationBar from './NavigationBar';

function NavigationBarContainer(props) {
    return <NavigationBar isLoggedIn={props.isLoggedIn} />;
}

NavigationBarContainer.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth }) => ({
    isLoggedIn: Boolean(auth.currentUser),
});

export default connect(mapStateToProps)(NavigationBarContainer);
